import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Button} from "primeng/button";
import {DialogModule} from "primeng/dialog";
import {DropdownModule} from "primeng/dropdown";
import {FloatLabelModule} from "primeng/floatlabel";
import {InputTextModule} from "primeng/inputtext";
import {MultiSelectModule} from "primeng/multiselect";
import {NgIf} from "@angular/common";
import {PaginatorModule} from "primeng/paginator";
import {Crew} from "../../models/Crew";
import {CrewMember} from "../../models/CrewMember";
import {SecurityQueryResult} from "../securityQuery/securityQuery.component";
import {CrewService} from "../../services/crew.service";
import {AlertModalService} from "../alert-modal/alert-modal.service";
import {LoaderService} from "../loader/loader.service";
import {SecurityQueryService} from "../securityQuery/securityQuery.service";
import {BehaviorSubject, Subject} from "rxjs";
import _ from "lodash";

@Component({
  selector: 'app-crew-creator-dialog',
  standalone: true,
  imports: [
    Button,
    DialogModule,
    DropdownModule,
    FloatLabelModule,
    InputTextModule,
    MultiSelectModule,
    NgIf,
    PaginatorModule
  ],
  templateUrl: './crew-creator-dialog.component.html',
  styleUrl: './crew-creator-dialog.component.scss'
})
export class CrewCreatorDialogComponent {
  @Input() crew?: Crew;
  @Input() crewMembers?: CrewMember[];

  private displaySubject = new BehaviorSubject<boolean>(false);

  @Output() displayChanged = new EventEmitter<boolean>();

  @Output() crewUpdated = new EventEmitter<Crew>();
  @Output() crewCreated = new EventEmitter<Crew>();
  @Output() crewDeleted = new EventEmitter<Crew>();
  @Output() crewMembersCreated = new EventEmitter<CrewMember>();

  selectedCrewMember?: CrewMember;
  showCrewMemberEditor = false;

  constructor(private crewService: CrewService,
              private alertService: AlertModalService,
              private loader: LoaderService,
              private securityQuery: SecurityQueryService) {
    this.displaySubject.subscribe(value => this.displayChanged.emit(value));
  }

  @Input() set display(value: boolean) {
    this.displaySubject.next(value);
    if (value) {
      this.showCrewMemberEditor = false;
      this.selectedCrewMember = new CrewMember();
      this.crewService.getCrewMembers().then(members => {
        this.crewMembers = members;
      });
      if (this.crew != undefined) {
        this.crewService.getCrew(this.crew!.id).then(crew => {
          this.crew = crew;
        });
      }
    }
  }

  get display(): boolean {
    return this.displaySubject.value;
  }

  selectCrewMember(crewMember: CrewMember | null = null) {
    if (crewMember == null) {
      this.selectedCrewMember = new CrewMember();
    }
    this.showCrewMemberEditor = true;
  }

  async saveCrewMember() {
    if (this.selectedCrewMember == null) {
      this.alertService.show('Es wurde kein Crewmitglied ausgewählt.');
      return;
    }
    try {
      if (this.selectedCrewMember?.id == null) {
        this.loader.visibility = true;
        const member = await this.crewService.postCrewMember(this.selectedCrewMember);
        this.crewMembers?.push(member);
        this.showCrewMemberEditor = false;
      }
    } catch (e) {
      //
    } finally {
      this.loader.visibility = false;
    }
  }

  async saveCrew() {
    if (this.crew == null) {
      this.alertService.show('Es wurde keine Crew ausgewählt.');
      return;
    }
    if (this.isCrewEmpty()) {
      this.alertService.show('Die Crew ist leer.');
      return;
    }
    try {
      if (this.crew.id == null || this.crew.id <= 0) {
        this.loader.visibility = true;
        this.crew = await this.crewService.postCrew(this.crew);
        this.crewCreated.emit(this.crew);
      } else {
        await this.crewService.patchCrew(this.crew.id, this.crew);
        this.crewUpdated.emit(this.crew);
      }
    } catch (e) {
      //
    } finally {
      this.loader.visibility = false;
    }
  }

  async deleteCrew() {
    if (this.crew?.id == null) {
      return;
    }
    if (await this.securityQuery.show('Möchten Sie die Crew wirklich löschen?', true, false, true) !== SecurityQueryResult.Yes) {
      return;
    }
    try {
      this.loader.visibility = true;
      await this.crewService.deleteCrew(this.crew.id);
      this.crewDeleted.emit(this.crew);
      this.crew = new Crew();
      this.display = false;
    } catch (e) {
      //
    } finally {
      this.loader.visibility = false;
    }
  }

  async deleteAssignment(crewMember: CrewMember, type: string) {
    if (await this.securityQuery.show('Möchten Sie das Crew-Mitglied wirklich aus der Crew entfernen?', true, false, true) !== SecurityQueryResult.Yes) {
      return;
    }
    if (this.crew === undefined) {
      return;
    }
    try {
      this.loader.visibility = true;
      await this.crewService.deleteAssignmentOfCrewMember(this.crew.id, crewMember.id, type);
      this.crew = await this.crewService.getCrew(this.crew.id);
      this.crewUpdated.emit(this.crew);
    } finally {
      this.loader.visibility = false;
    }
  }

  isCrewEmpty() {
    if (this.crew == null) {
      return true;
    }
    return this.crew.skipper == null && this.crew.coSkipper == null && this.crew.smutje == null && this.crew.navigator == null
      && (this.crew.crewMembers == null || this.crew.crewMembers.length <= 0);
  }

  crewMemberString(crew: Crew) {
    const crewRoles = [
      {member: crew.skipper},
      {member: crew.coSkipper},
      {member: crew.navigator},
      {member: crew.smutje},
    ];

    const crewMembers = crewRoles
      .filter(({member}) => member != null)
      .map(({member}) => `${member?.name}`);

    if (crew.crewMembers != null) {
      crewMembers.push(...crew.crewMembers.map(member => `${member.name}`));
    }

    if (crewMembers.length === 0) {
      return '';
    } else if (crewMembers.length === 1) {
      return crewMembers[0];
    } else {
      const lastMember = crewMembers.pop();
      return `${crewMembers.join(', ')} und ${lastMember}`;
    }
  }
}
