<div *ngIf="trackRecord !== undefined" class="track-record-wrapper">
  <div class="track-data">
    <img src="assets/stats/clock.svg">
    <div>
      <span>{{ time }} Uhr</span>
      <label>Uhrzeit</label>
    </div>
  </div>
  <div class="track-data">
    <img src="assets/stats/speedometer.svg">
    <div>
      <span>{{ speed }} kn</span>
      <label>Geschwindigkeit</label>
    </div>
  </div>
  <div class="track-data">
    <img src="assets/stats/wind.svg">
    <div>
      <span>{{ windSpeed }} kn</span>
      <label>Wind</label>
    </div>
  </div>
  <div class="track-data">
    <img src="assets/stats/wind.svg">
    <div>
      <span>{{ windDirection }} °</span>
      <label>Windrichtung</label>
    </div>
  </div>
  <div class="track-data">
    <img src="assets/stats/thermometer.svg">
    <div>
      <span>{{ temperature }} °C</span>
      <label>Temperatur</label>
    </div>
  </div>
</div>
