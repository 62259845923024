import {Gateway} from "./Gateway";
import {Journal} from "./Journal";
import {MapType} from "../classes/MapType";
import {User} from "./User";

export class UserDetails {
  id!: number;
  firstname!: string;
  lastname!: string;
  user!: User;
  gateways!: Gateway[];
  permitted_gateways!: Gateway[];
  journals!: Journal[];
  phone!: string;
  phoneVerified!: boolean;
  usedStorageSpace!: number;
  mapType!: MapType;
}
