import {Injectable, OnInit} from "@angular/core";
import {Map} from "ol";
import {OpenlayersExtension} from "../../../extensions/openlayers.extension";

declare const mapkit: any;

@Injectable()
export class JournalPreviewMapService extends OpenlayersExtension {

  map?: Map;

  constructor() {
    super();
  }

  async init(lat: number, lon: number) {
    this.map = this.createMap(lon, lat, 'map-container');
    this.addStaticLayer(this.map, 'openstreetmap');
    // this.addStaticLayer(this.map, 'openseamap');
  }

  resetMap() {
    if (this.map !== undefined) {
      this.map.dispose();
      this.map = undefined;
    }
  }

}
