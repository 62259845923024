import {NgModule} from "@angular/core";
import {CryptoService} from "./crypto.Service";
import {BaseService, HttpMethod} from "./base.service";
import {Session} from "../models/Session";

@NgModule()
export class AuthService {

  constructor(private base: BaseService, private crypto: CryptoService) {
    // env.apiUrl
  }

  async login(login: string, password: string, stayLoggedIn: boolean = false) {
    return this.base.executeRequest<Session>('/auth/login', HttpMethod.POST, {
      ec: true,
      login: await this.crypto.encryptSingleValue(login),
      password: await this.crypto.encryptSingleValue(password),
      pubKey: this.crypto.clientPubKey,
      stayLoggedIn
    });
  }

  async register(email: string, password: string) {
    return this.base.executeRequest<void>('/auth/register', HttpMethod.POST, {
      email, password
    });
  }

  confirmOTP(token: string) {
    return this.base.executeRequest('/otp', HttpMethod.POST, {
      token
    });
  }

  passwordResetRequest(email: string) {
    return this.base.executeRequest('/auth/password/reset-request', HttpMethod.POST, {
      email
    });
  }

  passwordReset(email: string, code: number, password: string) {
    return this.base.executeRequest('/auth/password/reset', HttpMethod.POST, {
      email,
      code,
      password
    });
  }

  enableOTP(otpCode: number, password: string) {
    return this.base.executeRequest('/otp/enable', HttpMethod.POST, {
      otpCode,
      password
    });
  }

  disableOTP(password: string) {
    return this.base.executeRequest('/otp/disable', HttpMethod.POST, {
      password
    });
  }

  getCurrentUserDetails<T>() {
    return this.base.executeRequest<T>('/auth/user', HttpMethod.GET);
  }

  checkSession() {
    return this.base.executeRequest<Session>('/auth/session', HttpMethod.GET, {}, false, false, undefined, false);
  }

  logout() {
    return this.base.executeRequest('/auth/logout', HttpMethod.POST);
  }

  requestVerify(method: string = 'email') {
    return this.base.executeRequest<void>('/session/verify/request', HttpMethod.POST, {
      method
    });
  }

  verifySession(token: string) {
    return this.base.executeRequest<void>('/session/verify', HttpMethod.POST, {
      token
    });
  }

  requestLoginWithMail(email: string) {
    return this.base.executeRequest<void>('/auth/login/mail/request', HttpMethod.POST, {
      email
    });
  }

  confirmLoginWithMail(secret: string, stayLoggedIn: boolean = false) {
    return this.base.executeRequest<Session>('/auth/login/mail/confirm', HttpMethod.POST, {
      secret,
      stayLoggedIn
    });
  }

  savePubKey(pubKey: string) {
    return this.base.executeRequest<void>('/auth/pubKey', HttpMethod.PATCH, {
      pubKey
    });
  }

}
