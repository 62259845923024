<div class="wrapper">
  <div class="title">
    Benutzerverwaltung
  </div>
  <p-progressBar *ngIf="users == undefined"
                 mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
  <div *ngIf="users !== undefined" class="data-view">
    <div *ngFor="let item of users; let i = index" class="data-view-card"
         [ngClass]="isEven(i) ? 'dark-row' : ''">
      <div class="data-view-item">
        <label>Vorname</label>
        <div *ngIf="!editMode" (click)="editMode = true">{{ item.firstname }}</div>
        <input pInputText *ngIf="editMode" (keydown)="leaveEditMode($event, item)" [(ngModel)]="item.firstname"
               type="text">
      </div>
      <div class="data-view-item">
        <label>Nachname</label>
        <div *ngIf="!editMode" (click)="editMode = true">{{ item.lastname }}</div>
        <input pInputText *ngIf="editMode" (keydown)="leaveEditMode($event, item)" [(ngModel)]="item.lastname"
               type="text">
      </div>
      <div class="data-view-item">
        <label>E-Mail</label>
        <div *ngIf="!editMode" (click)="editMode = true">{{ item.user.email }}</div>
        <input pInputText *ngIf="editMode" (keydown)="leaveEditMode($event, item)" [(ngModel)]="item.user.email"
               type="text">
      </div>
      <div class="data-view-item">
        <label>Telefon</label>
        <div *ngIf="!editMode" (click)="editMode = true">{{ item.phone }}</div>
        <input pInputText *ngIf="editMode" (keydown)="leaveEditMode($event, item)" [(ngModel)]="item.phone" type="text">
      </div>
      <div class="data-view-item sm">
        <label>Speicherbelegung</label>
        <div [innerText]="calcStorageUsed(item)"></div>
      </div>
      <div class="data-view-item sm" (click)="gatewayManagement.open(item)">
        <label>BlackBox - Geräte</label>
        <div>{{ item.gateways.length + item.permitted_gateways.length }}</div>
      </div>
      <div class="data-view-item sm">
        <label>Törns</label>
        <div>{{ item.journals.length }}</div>
      </div>
      <div class="data-view-item sm">
        <i (click)="loginAs(item)" class="pi pi-sign-in"></i>
      </div>
    </div>
  </div>
</div>

<app-gateway-management/>
