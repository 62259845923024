<p-dialog *ngIf="crew != null"
  [header]="crew.id === undefined ? 'Neue Crew' : crewMemberString(crew!)"
  [modal]="true"
  [(visible)]="display"
  [style]="{ width: '50rem'}"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [maximizable]="true">
  <div *ngIf="crewMembers !== undefined" class="crew-container">
    <div class="crew">
      <div class="crew-item">
        <label>Skipper:</label>
        <p-dropdown
          class="p-fluid"
          [options]="crewMembers"
          [(ngModel)]="crew!.skipper"
          (ngModelChange)="saveCrew()"
          placeholder="Wähle einen Skipper"
          optionLabel="name"/>
        <i *ngIf="crew!.skipper !== undefined"
           (click)="deleteAssignment(crew!.skipper, 'skipper')" class="pi pi-trash"></i>
      </div>
      <div class="crew-item">
        <label>CoSkipper:</label>
        <p-dropdown
          class="p-fluid"
          [options]="crewMembers"
          [(ngModel)]="crew!.coSkipper"
          (ngModelChange)="saveCrew()"
          placeholder="Wähle einen CoSkipper"
          optionLabel="name"/>
        <i *ngIf="crew!.coSkipper !== undefined"
           (click)="deleteAssignment(crew!.coSkipper, 'coSkipper')" class="pi pi-trash"></i>
      </div>
      <div class="crew-item">
        <label>Navigator:</label>
        <p-dropdown
          class="p-fluid"
          [options]="crewMembers"
          [(ngModel)]="crew!.navigator"
          (ngModelChange)="saveCrew()"
          placeholder="Wähle einen Navigator"
          optionLabel="name"/>
        <i *ngIf="crew!.navigator !== undefined"
           (click)="deleteAssignment(crew!.navigator, 'navigator')" class="pi pi-trash"></i>
      </div>
      <div class="crew-item">
        <label>Smutje:</label>
        <p-dropdown
          class="p-fluid"
          [options]="crewMembers"
          [(ngModel)]="crew!.smutje"
          (ngModelChange)="saveCrew()"
          placeholder="Wähle einen Smutje"
          optionLabel="name"/>
        <i *ngIf="crew!.smutje !== undefined"
           (click)="deleteAssignment(crew!.smutje, 'smutje')" class="pi pi-trash"></i>
      </div>
      <div class="crew-item">
        <label>Crew:</label>
        <p-multiSelect
          class="p-fluid"
          [options]="crewMembers"
          [(ngModel)]="crew!.crewMembers"
          (ngModelChange)="saveCrew()"
          appendTo="body" [showClear]="true"
          styleClass="showAbove"
          placeholder="Wähle Crew-Mitglieder"
          optionLabel="name"/>
      </div>
    </div>
    <div class="col" style="gap: 0.5rem;">
      <div class="info">
        <i class="pi pi-info-circle"></i>
        <span>
          Wenn du die gewünschte Person nicht in den obigen Auswahlfeldern findest, befindet sich diese nicht in deinem Adressbuch.
          Über die unten stehenden Schaltfläche kannst du die Person hinzufügen.
        </span>
      </div>
      <div class="row">
        <p-button class="auto-width" (click)="selectCrewMember(null)" label="Neues Person"/>
        <!-- <p-button *ngIf="crew?.id !== undefined" class="auto-width" (click)="deleteCrew()" severity="danger"
                  label="Crew Löschen"/> -->
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog header="Crew-Mitglied"
          [(visible)]="showCrewMemberEditor"
          [style]="{ width: '25rem' }">
  <div *ngIf="selectedCrewMember !== undefined" class="crew-container"
       style="gap: 1.75rem; padding: 1.25rem 0;">
    <p-floatLabel class="p-fluid">
      <input id="name" type="text" pInputText [(ngModel)]="selectedCrewMember!.name"/>
      <label for="name">Name</label>
    </p-floatLabel>
    <p-floatLabel class="p-fluid">
      <input id="email" type="email" pInputText [(ngModel)]="selectedCrewMember!.email"/>
      <label for="email">E-Mail</label>
    </p-floatLabel>
    <div class="row">
      <p-button (onClick)="saveCrewMember()" label="Übernehmen"/>
    </div>
  </div>
</p-dialog>
