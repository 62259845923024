import {Component, OnInit} from '@angular/core';
import {RegistryService} from "../../../services/registry.service";
import {Router} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {GatewayManagementComponent} from "../gateway-management/gateway-management.component";
import {GatewayManagementService} from "../gateway-management/gateway-management.service";
import {TableModule} from "primeng/table";
import {InputTextModule} from "primeng/inputtext";
import {ProgressBarModule} from "primeng/progressbar";
import {TrackDataKey} from "../../../models/TrackDataKey";
import {PermissionType} from "../../../classes/PermissionType";

@Component({
  selector: 'app-trackDataKey-management',
  standalone: true,
  imports: [
    FormsModule,
    NgForOf,
    NgIf,
    NgClass,
    GatewayManagementComponent,
    TableModule,
    InputTextModule,
    ProgressBarModule
  ],
  templateUrl: './trackDataKey-management.component.html',
  styleUrl: './trackDataKey-management.component.scss'
})
export class TrackDataKeyManagementComponent implements OnInit {

  keys?: TrackDataKey[];

  editMode: boolean = false;

  constructor(private services: RegistryService, private router: Router, public gatewayManagement: GatewayManagementService) {
    //
  }

  async ngOnInit() {

    if (!await this.services.permission.checkReadable(PermissionType.TrackDataKeys)) {
      await this.router.navigate(['/login']);
    }

    this.keys = await this.services.tracks.getTrackDataKeys()
  }

  isEven(i: number) {
    return i % 2 === 0;
  }

  async leaveEditMode(event: KeyboardEvent, item: TrackDataKey) {
    if (event.key === "Enter") {
      try {
        await this.services.tracks.updateTrackDataKey(item);
        this.editMode = false;
      } catch (e) {
        //
      }
    }
  }

}
