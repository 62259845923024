<div class="wrapper">
  <div class="title">
    System - Jobs
  </div>
  <div class="jobs">
    <div *ngFor="let job of jobs" class="job">
      <div class="job-title">
        <span [innerText]="job.name"></span>
      </div>
      <div class="job-description">
        <span [innerText]="job.description"></span>
      </div>
      <div class="job-actions">
        <p-button (onClick)="executeJob(job)" size="small" label="Ausführen"/>
      </div>
    </div>
  </div>
</div>
