<div class="wrapper">
  <div class="title">
    Mitgliedschaften
  </div>

  <p-progressBar *ngIf="isLoading"
                 mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>

  <div *ngIf="validMemberships !== undefined" class="data-view">
    <div *ngFor="let item of validMemberships; let i = index" class="data-view-card"
         [ngClass]="isEven(i) ? 'dark-row' : ''" (click)="openContextMenu(item)">
      <div class="data-view-item">

        <p-dialog header="Verwaltung der Mitgliedschaft" [(visible)]="item.modalVisible" [style]="{ width: '25rem' }">
          <div class="overlay">
            <p-button severity="danger" class="p-fluid" (click)="revokeSelectedMembership()" label="Wiederrufen"
                      icon="pi pi-trash"></p-button>
            <p-button class="p-fluid" (click)="item.modalVisible = false" label="Schließen"
                      icon="pi pi-times"></p-button>
          </div>
        </p-dialog>

        <label>Bezeichnung</label>
        <div>{{ item.membership.name }}</div>
      </div>
      <div class="data-view-item">
        <label>Account Name</label>
        <div>{{ item.user.lastname }}, {{ item.user.firstname }}</div>
      </div>
      <div class="data-view-item">
        <label>Account E-Mail</label>
        <div>{{ getMailOfUser(item.user.id) }}</div>
      </div>
      <div class="data-view-item">
        <label>Aktivierungsdatum</label>
        <div>{{ getTime(item.createdAt) }}</div>
      </div>
      <div class="data-view-item">
        <label>Ablaufdatum</label>
        <div>{{ item.expiredAt != null ? getTime(item.expiredAt!) : 'nie' }}</div>
      </div>
    </div>
  </div>

  <div *ngIf="!isLoading" class="creation">
    <p-dropdown
      class="p-fluid"
      [options]="users"
      [(ngModel)]="selectedUser"
      [showClear]="true"
      placeholder="Wähle einen Benutzer">
      <ng-template pTemplate="selectedItem">
        <div class="flex align-items-center gap-2">
          <div>{{ selectedUser?.lastname ?? '' }}, {{ selectedUser?.firstname ?? '' }}
            <{{ selectedUser?.user?.email ?? '' }}>
          </div>
        </div>
      </ng-template>
      <ng-template let-userObj pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div>{{ userObj.lastname }}, {{ userObj.firstname }} <{{ userObj.user.email }}></div>
        </div>
      </ng-template>
    </p-dropdown>
    <p-dropdown
      class="p-fluid"
      [options]="availableMemberships"
      [(ngModel)]="selectedMembershipType"
      [showClear]="true"
      placeholder="Wähle eine Mitgliedschaft">
      <ng-template pTemplate="selectedItem">
        <div class="flex align-items-center gap-2">
          <div>{{ selectedMembershipType?.name ?? '' }}</div>
        </div>
      </ng-template>
      <ng-template let-membership pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div>{{ membership.name }}</div>
        </div>
      </ng-template>
    </p-dropdown>
    <p-calendar
      class="p-fluid"
      [(ngModel)]="expiredAt"
      [iconDisplay]="'input'"
      [showIcon]="true"
      placeholder="Ablaufdatum"
      inputId="icondisplay"></p-calendar>
    <p-button (onClick)="addMembership()" label="Hinzufügen" icon="pi pi-check"></p-button>
  </div>

  <div class="data-view">
    <div *ngFor="let item of availableMemberships; let i = index" class="data-view-card"
         [ngClass]="isEven(i) ? 'dark-row' : ''">
      <div class="data-view-item" style="width: 100%">
        <label>Bezeichnung</label>
        <div>{{ item.name }}</div>
      </div>
      <div *ngFor="let prop of item.properties" (click)="memberShipEdit = true" class="data-view-item">
        <label>{{ prop.name }}</label>
        <div *ngIf="!memberShipEdit">{{ isSize(prop.name) ? getSize(prop.value) : prop.value }}</div>
        <input pInputText *ngIf="memberShipEdit" type="text" [(ngModel)]="prop.value"
               (keydown)="memberShipKeyDown($event)" class="p-auto">
      </div>
    </div>
  </div>

</div>
