import {Component, Input} from '@angular/core';
import {TrackRecord} from "../../models/TrackRecord";
import {JournalEntity} from "../../models/JournalEntity";
import moment from "moment/moment";
import {NgIf} from "@angular/common";
import {ExtractDataService} from "../../services/extractData.service";

@Component({
  selector: 'app-track-record-data',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './track-record-data.component.html',
  styleUrl: './track-record-data.component.scss'
})
export class TrackRecordDataComponent {

  @Input() trackRecord?: TrackRecord;

  constructor(private extractService: ExtractDataService) {
    //
  }

  get time() {
    moment.locale('de');
    return moment(this.trackRecord?.createdAt).format('HH:mm');
  }

  get temperature() {
    const data = this.extractService.getOutsideTemperature(this.trackRecord?.data ?? []);
    if (data === 0) {
      return '-/-';
    }
    return (data - 273.15).toFixed(1).replace('.', ',');
  }

  get speed() {
    const data = this.extractService.getSpeed(this.trackRecord?.data ?? []);
    if (data === 0) {
      return '-/-';
    }
    return data.toFixed(1).replace('.', ',');
  }

  get windSpeed() {
    const data = this.extractService.getWindSpeed(this.trackRecord?.data ?? []);
    if (data === 0) {
      return '-/-';
    }
    return data.toFixed(1).replace('.', ',');
  }

  get windDirection() {
    const data = this.extractService.getWindAngle(this.trackRecord?.data ?? []);
    if (data === 0) {
      return '-/-';
    }
    return data.toFixed(1).replace('.', ',');
  }

}
