<div *ngIf="selectedJournal == null" class="wrapper">

  <div class="title">
    Deine Törns
  </div>

  <div class="welcome-text">
    <i class="pi pi-info-circle"></i>
    <p>
      Ein Logbuch ist mehr als nur die Erfüllung einer Sorgfaltspflicht. Lege hier Törns an, erfasse wichtige Ereignisse wie Position, Wetter und Kurs, ergänze sie mit persönlichen Erlebnissen, Fotos und Videos. Ob unterwegs per Spracheingabe oder beim Nachbereiten vergangener Törns - deine Erinnerungen werden sicher in einem deutschen Rechenzentrum gespeichert und können mit deiner Crew geteilt werden.
    </p>
  </div>

  <div *ngIf="journals.length <= 0" class="welcome">
    <img src="assets/logbook.png">
    <div class="text">
      <h1>Dein Logbuch ist noch leer...</h1>
      <span>
        Begriffe wie gute Seemannschaft, Tradition oder Sorgfaltspflicht benutzen die einen, wenn sie über das Führen des Logbuchs sprechen; Überregulierung, Gängelei, hört man von anderen. Es herrscht Unsicherheit darüber, ob auf Sportbooten überhaupt ein Logbuch geführt werden muss. Und wenn ja, ab welcher Schiffsgröße, in welcher Form und was gehört hinein? Sicher ist aber eins. Im Falle eines Unfalls oder einer Havarie hast du vor dem Seegericht ohne Logbuch immer die schlechteren Karten.
      </span>
      <span>
        Dein digitales Logbuch ist noch leer. Unsere App hilft dir nun bei der Strukturierung und Visualisierung deines Törns. Egal ob du gerade unterwegs bist, oder ob du vergangene Törns Aufbereiten möchtest.
      </span>
      <span>
        Nachdem du einen Törn mit den Details zur Crew, zum Schiff, zur Zeit angelegt hast, kannst du beginnen es mit Inhalten zu füllen. Du kannst nun für jeden einzelnen Tag deines Törns Ereignisse hinzufügen. Wie ist wie meine Position, das Wetter, der Kurs, die Geschwindigkeit, die Besegelung. Was hat mich sehr beindruckt, wie habe ich mich gefühlt, wie war die Stimmung etc.
      </span>
      <!-- <div class="alert">
        <img src="assets/warning.svg">
        <div>An dieser Stelle werden deine Reisen angezeigt, sobald vorhanden.</div>
      </div> -->
      <h2>Wie möchtest du beginnen?</h2>
      <div class="welcome-actions">
        <div class="global-create-btn" (click)="creationModal = true">
          <i class="pi pi-file-plus"></i>
          <span>Eigenen Törn anlegen</span>
        </div>
        <div class="global-create-btn" (click)="createDummyJournal()">
          <i class="pi pi-file-plus"></i>
          <span>Beispiel Törn generieren</span>
        </div>
      </div>
    </div>
  </div>

  <div class="journals">
    <div *ngFor="let item of sortedJournals" class="journal-wrapper">
      <span class="year">{{ item.year > 0 ? item.year : 'kein Jahr zugewiesen' }}</span>
      <span class="line underline"></span>
      <div class="journals-list">
        <div *ngFor="let journal of item.journals" class="journal">
          <div class="head" (click)="openJournal(journal, false)">
            <div class="title row" style="align-items: center; gap: 0.5rem;">
              <img [src]="getCountryFlagIcon(journal.country)"
                   style="width: 22px; border-radius: 0.1rem;"/>
              <span [innerText]="journal?.title ?? ''"></span>
            </div>
            <div class="details">
              <div class="detail">
                <i class="pi pi-calendar"></i>
                <div [innerText]="journal.year"></div>
              </div>
              <div *ngIf="journal.miles != null"
                   pTooltip="Nautische Meilen"
                   tooltipPosition="bottom"
                   class="detail">
                <i class="pi pi-map"></i>
                <div>{{ journal.miles }} NM</div>
              </div>
            </div>
          </div>
          <div class="data-entity" (click)="openJournal(journal, false)">
            <div class="row" style="gap: 0.5rem; align-items: center;">
              <label>Land (Revier)</label>
            </div>
            <span>{{ journal.country }} ({{ journal.revier }})</span>
          </div>
          <div class="data-entity" (click)="openJournal(journal, false)">
            <label>Boot</label>
            <span>{{ journal?.boatName ?? '-/-' }} ({{ journal?.boatType ?? '-/-' }})</span>
          </div>
          <div class="actions">
            <i (click)="openJournal(journal, false)" class="pi pi-eye"></i>
            <i *ngIf="!journal.readonly" (click)="openJournal(journal, true)" class="pi pi-pencil"></i>
          </div>
        </div>
      </div>
      <span class="line splitter"></span>
    </div>
  </div>

  <div class="global-create-btn" *ngIf="journals.length > 0" (click)="creationModal = true">
    <i class="pi pi-file-plus"></i>
    <span>Neuer Törn</span>
  </div>

</div>

<app-journal-preview *ngIf="showJournalPreview" [journal]="previewJournal"
                     (close)="showJournalPreview = false; previewJournal = undefined"/>

<app-journal-creation *ngIf="creationModal" (created)="loadJournals()" (closed)="creationModal = false"/>
