<div id="map-container">
  <div class="watermark">
    <label>Logbuch</label>
    <span>by VDR.TECHNOLOGY</span>
  </div>
  <div *ngIf="!readonly" (click)="exit()" class="editMode">
    <i class="pi pi-pen-to-square"></i>
    <label>Bearbeitungsmodus</label>
  </div>
</div>
