<div *ngIf="service.visible" class="wrapper">
  <div class="container">
    <div class="title">
      <div>Geräteverwaltung</div>
      <span>{{ service.user?.user?.email }}</span>
    </div>
    <div class="gateway-wrapper">
      <div class="gateways">
        <div class="gateway header">
          <div class="gateway-label">
            Name
          </div>
          <div class="actions">
            <div>
              Owner
            </div>
            <div>
              Member
            </div>
          </div>
        </div>
        <div *ngFor="let gateway of service.gateways" class="gateway">
          <div class="gateway-label">
            <span *ngIf="!editMode" (click)="editMode = true">{{ gateway.name }}</span>
            <input *ngIf="editMode" (keydown)="leaveEditMode($event, gateway)" [(ngModel)]="gateway.name" type="text">
          </div>
          <div class="actions">

            <div class="checkbox-wrapper-12">
              <div class="cbx">
                <input [(ngModel)]="gateway.isOwner" (ngModelChange)="setOwner(gateway, $event)" id="cbx-1"
                       type="checkbox"/>
                <label for="cbx-1"></label>
                <svg width="15" height="14" viewbox="0 0 15 14" fill="none">
                  <path d="M2 8.36364L6.23077 12L13 2"></path>
                </svg>
              </div>
            </div>

            <div class="checkbox-wrapper-12">
              <div class="cbx">
                <input [(ngModel)]="gateway.isMember" (ngModelChange)="setMember(gateway, $event)" id="cbx-2"
                       type="checkbox"/>
                <label for="cbx-2"></label>
                <svg width="15" height="14" viewbox="0 0 15 14" fill="none">
                  <path d="M2 8.36364L6.23077 12L13 2"></path>
                </svg>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <p-button (click)="service.visible = false" [text]="true" severity="secondary" label="Schließen"/>
    </div>
  </div>
</div>
