import {Injectable} from "@angular/core";
import {UserDetailsService} from "./userDetails.service";
import {JournalService} from "./journal.service";
import {IssueService} from "./issue.service";
import {StatsService} from "./stats.service";
import {AdminService} from "./admin.service";
import {GatewayService} from "./gateway.service";
import {TracksService} from "./tracks.service";
import {InfoService} from "./info.service";
import {MembershipService} from "./membership.service";
import {MapKitService} from "./mapkit.service";
import {ExternalService} from "./external.service";
import {PaymentService} from "./payment.service";
import {AuthService} from "./auth.Service";
import {UserService} from "./user.Service";
import {PermissionService} from "./permission.Service";
import {WebAuthnService} from "./webAuthn.service";
import {SettingsService} from "./settings.service";
import {CrewService} from "./crew.service";
import {CmsService} from "./cms.service";

@Injectable()
export class RegistryService {

  constructor(public journal: JournalService,
              public userDetails: UserDetailsService,
              public auth: AuthService,
              public user: UserService,
              public permission: PermissionService,
              public issue: IssueService,
              public stats: StatsService,
              public fido2: WebAuthnService,
              public admin: AdminService,
              public gateway: GatewayService,
              public tracks: TracksService,
              public info: InfoService,
              public membership: MembershipService,
              public mapKit: MapKitService,
              public external: ExternalService,
              public payment: PaymentService,
              public settings: SettingsService,
              public crewService: CrewService,
              public cms: CmsService) {
    //
  }

}
