import {Injectable} from "@angular/core";
import {BaseService, HttpMethod} from "./base.service";
import {RestCountry} from "../classes/RestCountry";

@Injectable()
export class ExternalService {

  constructor(private base: BaseService) {
    //
  }

  async getCountries(search: string) {
    return await this.base.executeRequest<RestCountry[]>(`https://restcountries.com/v3.1/translation/${search}`, HttpMethod.GET);
  }

}
