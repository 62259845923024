import {Journal} from "../models/Journal";
import _ from "lodash";

export class JournalHelper {

  static createUpdateDto(journal: Journal): Journal {
    const journalCopy = _.cloneDeep(journal);
    journalCopy.journals.forEach(entity => entity.assets = []);
    journalCopy.journals.forEach(entity => entity.galleryImages = []);
    return Object.assign(new Journal(), journalCopy) as Journal;
  }

}
