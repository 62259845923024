import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivatedRoute, RouterLink, RouterOutlet} from '@angular/router';
import {SortComponent} from "./sort/sort.component";
import {AppService} from "./app.service";
import {} from "@angular/common/http";
import {AlertModalComponent} from "./alert-modal/alert-modal.component";
import {LoaderComponent} from "./loader/loader.component";
import {environment} from "../environments/environment";
import {SecurityQueryComponent} from "./securityQuery/securityQuery.component";
import {IssueComponent} from "./issue/issue.component";
import {PicturePreviewComponent} from "./picture-preview/picture-preview.component";
import {CrewEditorComponent} from "./crew-editor/crew-editor.component";
import {FooterComponent} from "./footer/footer.component";
import {VideoPlayerComponent} from "./video-player/video-player.component";
import {RegistryService} from "../services/registry.service";
import {ActiveMembership} from "../models/ActiveMembership";
import {HostService} from "../services/host.Service";
import {VerifySessionComponent} from "./verfiy-session/verify-session.component";
import {InputIconModule} from "primeng/inputicon";
import {FormsModule} from "@angular/forms";
import {TooltipModule} from "primeng/tooltip";
import {MenuModule} from "primeng/menu";
import {ButtonModule} from "primeng/button";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, SortComponent, AlertModalComponent, LoaderComponent, SecurityQueryComponent, RouterLink, IssueComponent, PicturePreviewComponent, CrewEditorComponent, FooterComponent, VideoPlayerComponent, VerifySessionComponent, InputIconModule, FormsModule, TooltipModule, MenuModule, ButtonModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'vdr-client';
  ownMembership?: ActiveMembership;
  searchValue: string = '';
  stats?: { miles: number, journals: number };

  menuItems = [
    {
      label: 'Optionen',
      items: [
        {
          label: 'Törns',
          icon: 'pi pi-book',
          routerLink: '/journal'
        },
        {
          label: 'Tracks',
          icon: 'pi pi-share-alt',
          routerLink: '/tracks'
        },
        /* {
          label: 'Deine Crews',
          icon: 'pi pi-users',
          routerLink: '/crews'
        }, */
        {
          label: 'Adressbuch',
          icon: 'pi pi-address-book',
          routerLink: '/contacts'
        },
        {
          label: 'Einstellungen',
          icon: 'pi pi-sliders-h',
          routerLink: '/settings'
        },
      ]
    }
  ];

  get DisplayName() {
    const user = this.appService.userDetails;
    if (user?.firstname != null && user.firstname != ''
      && user?.lastname != null && user.lastname != '') {
      return `${user.firstname} ${user.lastname}`;
    } else {
      return user?.user.email.split('@')[0];
    }
  }

  constructor(public hostService: HostService, public appService: AppService,
              private services: RegistryService, private route: ActivatedRoute) {
    this.hostService.host = environment.backend.host;
    this.hostService.port = +environment.backend.port;
    this.hostService.protocol = environment.backend.protocol;
    this.hostService.path = '/api/v1';
  }

  async ngOnInit() {

    const secret = this.route.snapshot.paramMap.get('secret');
    if (secret != null) {
      return;
    }

    await this.appService.checkAuth();

    this.setStats().then();

    /* this.ownMembership = await this.services.membership.getOwnMembership();
    this.appService.currentUserChanged.subscribe(async () => {
      this.ownMembership = await this.services.membership.getOwnMembership();
    }); */

    window.addEventListener("beforeunload", (e) => {
      if (this.appService.isDirty) {
        const confirmationMessage = "Sie haben ungespeicherte Änderungen. Wollen Sie die Seite wirklich verlassen?";
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
      return;
    });

    // console.log(mapkit.Libraries)

  }

  async setStats() {
    while (!this.appService.isAuth) {
      await new Promise(resolve => setTimeout(resolve, 200));
    }
    this.stats = await this.services.stats.getStats();
  }

  search() {
    this.appService.searchChanged.next(this.searchValue);
  }

}
