<div *ngIf="service.selectedVideo !== undefined" class="video-wrapper">
  <div class="close">
    <div class="img-wrapper">
      <img (click)="service.selectedVideo = undefined" src="assets/close-white.svg">
    </div>
    <div *ngIf="!service.isReadOnly" class="img-wrapper delete">
      <img (click)="deleteVideo()" src="assets/trash-bin.svg">
    </div>
  </div>
  <div class="video-container">
    <video controls autoplay (ended)="service.selectedVideo = undefined" >
      <source [attr.src]="service.getVideoUrl()" [type]="service.getVideoMimeType()">
    </video>
  </div>
</div>
