<div *ngIf="journal !== undefined" class="meta">
  <div class="meta-data">
    <div class="meta-item-wrapper">
      <div (click)="tryEdit()" class="meta-item" style="width: 100%">
        <div>
          <div>Titel:</div>
          <span *ngIf="!editMode" [innerText]="journal!.title"></span>
          <input (keydown)="keyDownEvent($event)"
                 (focusout)="cancelEdit()"
                 *ngIf="editMode" [(ngModel)]="journal!.title">
        </div>
        <img src="assets/pen.svg">
      </div>
      <div *ngIf="!journal?.readonly" (click)="shareButtonClicked.emit()" class="small-menu-item">
        <i class="pi pi-share-alt"></i>
        <label>Teilen</label>
      </div>
      <div *ngIf="!journal?.readonly" (click)="appButtonClicked.emit()" class="small-menu-item">
        <i class="pi pi-qrcode"></i>
        <label>App</label>
      </div>
    </div>
    <div class="meta-item-wrapper">
      <div (click)="tryEdit()" class="meta-item sm">
        <div>
          <div>Land:</div>
          <span *ngIf="!editMode" [innerText]="journal!.country"></span>
          <input (keydown)="keyDownEvent($event)"
                 (focusout)="cancelEdit()"
                 *ngIf="editMode" [(ngModel)]="journal!.country">
        </div>
        <img [src]="countryFlag">
      </div>
      <div (click)="tryEdit()" class="meta-item">
        <div>
          <div>Revier:</div>
          <span *ngIf="!editMode" [innerText]="journal!.revier"></span>
          <input (keydown)="keyDownEvent($event)"
                 (focusout)="cancelEdit()"
                 *ngIf="editMode" [(ngModel)]="journal!.revier">
        </div>
        <img src="assets/landscape.svg">
      </div>
    </div>
    <div class="meta-item-wrapper">
      <div (click)="tryEdit()" class="meta-item">
        <div>
          <div>Jahr:</div>
          <span *ngIf="!editMode" [innerText]="journal!.year"></span>
          <input (keydown)="keyDownEvent($event)"
                 (focusout)="cancelEdit()"
                 *ngIf="editMode" [(ngModel)]="journal!.year">
        </div>
        <img src="assets/calendar.svg">
      </div>
      <div (click)="tryEdit()" class="meta-item">
        <div>
          <div>Meilen:</div>
          <span *ngIf="!editMode && journal != null"
                [innerText]="journal!.miles > 0 ? journal!.miles + ' sm' : 'k.A.'"></span>
          <input (keydown)="keyDownEvent($event)"
                 (focusout)="cancelEdit()"
                 *ngIf="editMode" [(ngModel)]="journal!.miles"
                 placeholder="Seemeilen">
        </div>
        <img src="assets/route.svg">
      </div>
    </div>
    <div class="meta-item-wrapper">
      <div (click)="tryEdit()" class="meta-item sm">
        <div>
          <div>Boots-Name:</div>
          <span *ngIf="!editMode" [innerText]="journal!.boatName"></span>
          <input (keydown)="keyDownEvent($event)"
                 (focusout)="cancelEdit()"
                 *ngIf="editMode" [(ngModel)]="journal!.boatName">
        </div>
        <img src="assets/steering-wheel.svg">
      </div>
      <div (click)="tryEdit()" class="meta-item sm">
        <div>
          <div>Boots-Typ:</div>
          <span *ngIf="!editMode" [innerText]="journal!.boatType"></span>
          <input (keydown)="keyDownEvent($event)"
                 (focusout)="cancelEdit()"
                 *ngIf="editMode" [(ngModel)]="journal!.boatType">
        </div>
        <img src="assets/sailboat.svg">
      </div>
    </div>
  </div>
  <app-journal-map-overview [journal]="journal"/>
</div>
