<div class="cms-container">
  <div class="title">
    CMS Management
  </div>
  <div *ngFor="let item of sections" class="section">
    <div class="key">
      {{ item.key }}
    </div>
    <p-floatLabel>
      <input id="title" type="text"
             (focusout)="save(item)"
             [(ngModel)]="item.title" pInputText />
      <label for="title">Titel</label>
    </p-floatLabel>
    <p-editor [(ngModel)]="item.text"
              (focusout)="save(item)"
              [style]="{ height: '320px' }" />
    <span class="separator"></span>
  </div>
  <div class="title">
    FAQ - Fragen / Antworten
  </div>
  <div *ngFor="let item of faqItems" class="section">
    <div class="row" style="align-items: center; gap: 1rem;">
      <p-floatLabel>
        <input id="question" type="text"
               [(ngModel)]="item.question" pInputText />
        <label for="question">Frage</label>
      </p-floatLabel>
      <i style="width: auto;" class="pi pi-save" (click)="saveFaq(item)"></i>
      <i style="width: auto;" class="pi pi-trash" (click)="deleteFaq(item)"></i>
    </div>
    <p-editor [(ngModel)]="item.answer"
              [style]="{ height: '320px' }" />
    <span class="separator"></span>
  </div>
  <div class="global-create-btn" (click)="createBlankQuestion()">
    <i class="pi pi-file-plus"></i>
    <span>Neue Frage / Antwort</span>
  </div>
</div>
