import {Component, EventEmitter, Input, Output} from '@angular/core';
import {GalleriaModule} from "primeng/galleria";
import {NgForOf, NgIf} from "@angular/common";
import moment from "moment/moment";
import {JournalEntity} from "../../../models/JournalEntity";
import {JournalEntityAsset} from "../../../models/JournalEntityAsset";
import {AssetHelper} from "../../../helpers/AssetHelper";
import {GalleryItem} from "../../../classes/GalleryItem";
import {
  JournalPreviewEntityAssetsComponent
} from "../journal-preview-entity-assets/journal-preview-entity-assets.component";

@Component({
  selector: 'app-journal-entity-list',
  standalone: true,
  imports: [
    GalleriaModule,
    NgForOf,
    NgIf,
    JournalPreviewEntityAssetsComponent
  ],
  templateUrl: './journal-preview-entity-list.component.html',
  styleUrl: './journal-preview-entity-list.component.scss'
})
export class JournalPreviewEntityListComponent {
  @Input() entities: JournalEntity[] = [];

  @Output() imageClickEvent = new EventEmitter<{ index: number, entity: JournalEntity }>();
  @Output() imageKeyDownEvent = new EventEmitter<KeyboardEvent>();
  @Output() loadMoreEvent = new EventEmitter<JournalEntity>();

  imageClick(index: number, entity: JournalEntity) {
    this.imageClickEvent.emit({index, entity});
  }

  imageKeyDown(e: KeyboardEvent) {
    this.imageKeyDownEvent.emit(e);
  }

  isImage(asset: JournalEntityAsset) {
    return AssetHelper.isImage(asset);
  }

  loadMore(entity: JournalEntity) {
    this.loadMoreEvent.emit(entity);
  }

  getDate(date: Date) {
    moment.locale('de');
    return moment(new Date(date)).format('dddd, DD.MM.YYYY');
  }

}
