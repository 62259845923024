export enum PermissionType {
    Administrator = '*',
    Permission = 'permissions',
    User = 'users',
    Settings = 'settings',
    OpenAiUsage = 'openaiusage',
    Devices = 'devices',
    Gateways = 'gateways',
    TrackDataKeys = 'track_data_keys',
    PortfolioManagement = 'portfolio_management',
}