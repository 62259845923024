<div class="container">
  <div class="title">
    Dein Adressbuch
  </div>
  <div class="contacts">
    <div *ngFor="let contact of contacts" class="contact">
      <input type="text" placeholder="Name" pInputText
             [(ngModel)]="contact.name"/>
      <input type="email" placeholder="E-Mail" pInputText
             (focusout)="updateContact(contact)"
             [(ngModel)]="contact.email"/>
      <i class="pi pi-save m-hide" (click)="updateContact(contact)"></i>
      <i class="pi pi-trash" (click)="deleteContact(contact)"></i>
    </div>
  </div>
  <div class="global-create-btn" (click)="addContact()">
    <i class="pi pi-file-plus"></i>
    <span>Neuer Kontakt</span>
  </div>
</div>
