import {Injectable} from "@angular/core";

@Injectable()
export class AlertModalService {

  timeout: any;

  visibility = false;
  message = "";
  type = AlertType.Success;

  get AlertType() {
    return AlertType;
  }

  show(message: string, duration: number = 8000, type: AlertType = AlertType.Error) {
    if (this.timeout != null) {
      clearTimeout(this.timeout);
    }
    this.visibility = true;
    this.message = message;
    this.type = type;
    if (duration > 0) {
      this.timeout = setTimeout(() => {
        this.visibility = false;
      }, duration);
    }
  }

}

export enum AlertType {
  Success,
  Error,
}
