import {AfterViewInit, Component} from '@angular/core';
import {UserDetails} from "../../../models/UserDetail";
import {MapType} from "../../../classes/MapType";
import {AuthService} from "../../../services/auth.Service";
import {AlertModalService} from "../../alert-modal/alert-modal.service";
import {LoaderService} from "../../loader/loader.service";
import {RegistryService} from "../../../services/registry.service";
import {ButtonModule} from "primeng/button";
import {NgIf} from "@angular/common";
import {SelectButtonModule} from "primeng/selectbutton";
import {RadioButtonModule} from "primeng/radiobutton";
import {ProgressBarModule} from "primeng/progressbar";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-settings-general-tab',
  standalone: true,
  imports: [
    ButtonModule,
    NgIf,
    SelectButtonModule,
    RadioButtonModule,
    ProgressBarModule,
    FormsModule
  ],
  templateUrl: './settings-general-tab.component.html',
  styleUrl: './settings-general-tab.component.scss'
})
export class SettingsGeneralTabComponent implements AfterViewInit {

  user?: UserDetails;

  get MapType() {
    return MapType;
  }

  constructor(private authService: AuthService, private alertService: AlertModalService,
              private loader: LoaderService, private services: RegistryService) {
    //
  }

  async ngAfterViewInit() {
    try {
      this.user = await this.services.userDetails.getUserDetails() ?? undefined;
    } catch (e) {
      //
    }
  }

  async updateData() {

    if (this.user === undefined) {
      return;
    }

    this.loader.visibility = true;
    try {
      await this.services.userDetails.putUserDetails(this.user);
    } finally {
      this.loader.visibility = false;
    }
  }

}
