import {Injectable} from "@angular/core";
import {BaseSortDescription} from "./sort.component";
import {Subject} from "rxjs";

@Injectable()
export class SortService {

  sortDescriptions: string[] = [];

  visibility = false;

  selected: Subject<string> = new Subject<string>();
  selection: string = BaseSortDescription.default;

  show(possibleSelections: string[]): Promise<string> {
    return new Promise<string>(resolve => {
      this.sortDescriptions = [];
      this.sortDescriptions.push(...possibleSelections);
      this.sortDescriptions.push(BaseSortDescription.default.toString())
      this.visibility = true;
      this.selected.subscribe(x => {
        resolve(x);
        this.visibility = false;
      })
    });
  }

  select(selection: string) {
    this.selection = selection;
    this.selected.next(selection);
  }

}
