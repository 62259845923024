<div class="details-wrapper">
  <div class="details-container">
    <div class="title">
      Details
    </div>
    <div class="group">
      <label>
        Track-Beschreibung
      </label>
      <input type="text" pInputText
             [(ngModel)]="title"
             (focusout)="titleChanged.emit(title)"/>
      <label>
        Zeitpunkt der Aufnahme
      </label>
      <p-dropdown
        [options]="availableRecords"
        [(ngModel)]="selectedRecord"
        [style]="{width: '100%'}"
        (ngModelChange)="selectRecord($event)"
        placeholder="Uhrzeit">
        <ng-template let-record pTemplate="selectedItem">
          {{ formatDate(record.createdAt) }}
        </ng-template>
        <ng-template let-record pTemplate="item">
          {{ formatDate(record.createdAt) }}
        </ng-template>
      </p-dropdown>
      <label>
        Datenabfrage
      </label>
      <p-dropdown
        [options]="dataKeys"
        [(ngModel)]="selectedDataKey"
        [style]="{width: '100%'}"
        (ngModelChange)="selectDataKey($event)"
        placeholder="Datenabfrage">
        <ng-template let-key pTemplate="selectedItem">
          {{ key.description }}
        </ng-template>
        <ng-template let-key pTemplate="item">
          {{ key.description }}
        </ng-template>
      </p-dropdown>
    </div>
  </div>
</div>

<div *ngIf="selectedDataKey != undefined" class="data-overlay-wrapper">
  <div class="data-overlay-container">
    <div class="title">
      Datenabfrage
    </div>
    <div class="desc">
      <label [innerText]="selectedDataKey.description"></label>
      <span [innerText]="selectedDataKey.identifier"></span>
    </div>
    <div class="value" [innerText]="value"></div>
    <div (click)="selectedDataKey = undefined" class="close">
      Schließen
    </div>
  </div>
</div>
