import {Component, OnInit} from '@angular/core';
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {SortComponent} from "../sort/sort.component";
import {AppService} from "../app.service";
import {Router, RouterLink} from "@angular/router";
import {RegistryService} from "../../services/registry.service";
import {Track} from "../../models/Track";
import {LoaderService} from "../loader/loader.service";
import {UserDetails} from "../../models/UserDetail";
import {BaseService} from "../../services/base.service";
import moment from "moment";
import {LogPrefix} from "../app.config";
import {ButtonModule} from "primeng/button";
import {TooltipModule} from "primeng/tooltip";
import {NmeaConnectPreviewComponent} from "../nmea-connect-preview/nmea-connect-preview.component";
import {PermissionType} from "../../classes/PermissionType";

@Component({
  selector: 'app-tracks',
  standalone: true,
  imports: [
    NgForOf,
    NgClass,
    FormsModule,
    NgIf,
    SortComponent,
    RouterLink,
    ButtonModule,
    TooltipModule,
    NmeaConnectPreviewComponent
  ],
  templateUrl: './tracks.component.html',
  styleUrl: './tracks.component.scss'
})
export class TracksComponent implements OnInit {

  user?: UserDetails;
  tracks: Track[] = [];

  page = 1;
  take = 100;
  search = '';
  searchFieldVisibility = false;

  isAdmin = false;

  deleteMode = false;
  entityToDelete: number[] = [];

  get gateway() {
    return this.user?.gateways.find(g => g.id === this.base.selectedGateway)
      || this.user?.permitted_gateways.find(g => g.id === this.base.selectedGateway);
  }

  get possibleGateways() {
    return this.user?.gateways.concat(this.user.permitted_gateways);
  }

  constructor(private appService: AppService, private router: Router,
              private services: RegistryService, private loader: LoaderService,
              public base: BaseService) {
    //
  }

  async ngOnInit() {

    this.appService.checkAuth().then();

    while (this.appService.userDetails === undefined || !this.appService.isAuth) {
      console.debug(`${LogPrefix.D} Waiting for auth...`);
      await new Promise(r => setTimeout(r, 1000));
    }

    this.user = this.appService.userDetails;
    this.setGateway();

    if (this.base.selectedGateway > 0) {
      await this.loadTracks();
    }

    try {
      this.isAdmin = await this.services.permission.checkReadable(PermissionType.Administrator);
    } catch (e) {
      //
    }

  }

  async setGatewaySelection(gatewayId: number) {
    this.base.selectedGateway = gatewayId;
    await this.loadTracks();
  }

  isEven(i: number) {
    return i % 2 === 0;
  }

  async loadTracks() {
    try {
      this.loader.visibility = true;
      this.tracks = await this.services.tracks.getTracks(this.page, this.take);
      this.tracks = this.tracks.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    } finally {
      this.loader.visibility = false;
    }
  }

  setGateway() {
    if (this.user?.gateways.length === 1 && this.user.permitted_gateways.length <= 1) {
      this.base.selectedGateway = this.user.gateways[0].id;
    } else if (this.user?.permitted_gateways.length! > 0) {
      this.base.selectedGateway = this.user?.permitted_gateways[0].id!;
    }
  }

  getDate(date: Date) {
    moment.locale('de');
    return moment(date).format('L');
  }

  getTime(date: Date) {
    moment.locale('de');
    return moment(date).format('LT');
  }

  getDuration(start: Date, end: Date) {
    moment.locale('de');
    return moment(start).from(end).replaceAll('vor ', '').replaceAll('einer', 'eine');
  }

  async selectTrack(track: Track) {
    if (this.deleteMode) {
      if (this.entityToDelete.includes(track.id)) {
        this.entityToDelete = this.entityToDelete.filter(e => e !== track.id);
      } else {
        this.entityToDelete.push(track.id);
      }
      return;
    }
    await this.router.navigate(['/track', this.base.selectedGateway, track.id]);
  }

  getRowClasses(track: Track, i: number) {
    return {
      'dark-row': this.isEven(i),
      'marked': this.entityToDelete.includes(track.id)
    };
  }

  deleteSelection() {
    try {
      this.loader.visibility = true;
      this.entityToDelete.forEach(async id => {
        await this.services.tracks.deleteTrack(id);
        this.tracks = this.tracks.filter(t => t.id !== id);
        this.entityToDelete = this.entityToDelete.filter(e => e !== id);
      });
      this.deleteMode = false;
    } finally {
      this.loader.visibility = false;
    }
  }

}
