import { Component } from '@angular/core';
import {AlertModalService} from "./alert-modal.service";
import {NgClass, NgIf} from "@angular/common";

@Component({
  selector: 'app-alert-modal',
  standalone: true,
  imports: [
    NgIf,
    NgClass
  ],
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent {

  constructor(public service: AlertModalService) {
    //
  }

}
