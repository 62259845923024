import {inject} from "@angular/core";
import {SecurityQueryService} from "../securityQuery/securityQuery.service";
import {SecurityQueryResult} from "../securityQuery/securityQuery.component";
import {AppService} from "../app.service";

export const hasUnsavedChangesGuard = (async () => {
  const appService = inject(AppService);
  let shouldNavigate = true;
  if (appService.isDirty) {
    const modalService = inject(SecurityQueryService);
    shouldNavigate = await modalService.show('Sie haben ungespeicherte Änderungen. Wollen Sie die Seite wirklich verlassen?', true, true, false) == SecurityQueryResult.Yes;
    if (shouldNavigate) {
      appService.isDirty = false;
    }
  }
  return shouldNavigate;
});
