<div *ngIf="crew != null" (click)="showCrewSelector = !readonly" class="crew">
  <div class="row">
    <div class="title">
      Crew<!-- : {{ crewMemberString(crew) }} -->
    </div>
    <div *ngIf="crew.skipper != null && crew.skipper?.name != null"
         class="crew-member">
      <div class="img-wrapper">
        <img src="assets/profile-user.svg">
      </div>
      <div>
        <div>{{ crew.skipper!.name }}</div>
        <span>Skipper</span>
      </div>
    </div>
    <div *ngIf="crew.coSkipper != null && crew.coSkipper?.name != null"
         class="crew-member">
      <div class="img-wrapper">
        <img src="assets/profile-user.svg">
      </div>
      <div>
        <div>{{ crew.coSkipper!.name }}</div>
        <span>Co-Skipper</span>
      </div>
    </div>
    <div *ngIf="crew.navigator != null && crew.navigator!.name != null"
         class="crew-member">
      <div class="img-wrapper">
        <img src="assets/profile-user.svg">
      </div>
      <div>
        <div>{{ crew.navigator!.name }}</div>
        <span>Navigator</span>
      </div>
    </div>
    <div *ngIf="crew.smutje != null && crew.smutje!.name != null"
         class="crew-member">
      <div class="img-wrapper">
        <img src="assets/profile-user.svg">
      </div>
      <div>
        <div>{{ crew.smutje!.name }}</div>
        <span>Smutje</span>
      </div>
    </div>
    <div *ngFor="let crewMember of crew?.crewMembers ?? []"
         class="crew-member">
      <div class="img-wrapper">
        <img src="assets/profile-user.svg">
      </div>
      <div>
        <div>{{ crewMember.name }}</div>
        <span>Crew</span>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!disableInitialLoading && crew == null" class="no-crew">
  <label>Keine Crew ausgewählt</label>
  <button *ngIf="!readonly" (click)="showCrewSelector = true" class="p-button p-button-primary">Crew hinzufügen</button>
</div>

<app-crew-creator-dialog [crew]="crew" [display]="showCrewSelector"
                         (displayChanged)="onCloseCrewSelector($event)"
                         (crewCreated)="crew = $event; journal!.crew = $event;"
                         (crewUpdated)="crew = $event; journal!.crew = $event;"/>
