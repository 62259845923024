import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpEvent, HttpEventType} from "@angular/common/http";
import {catchError, EMPTY, Subject} from "rxjs";
import {Journal} from "../models/Journal";
import {BaseService, HttpMethod} from "./base.service";
import {AssetType} from "../classes/AssetType";
import {JournalStats} from "../classes/JournalStats";
import {JournalEntityAsset} from "../models/JournalEntityAsset";
import _ from 'lodash';
import {JournalHelper} from "../helpers/JournalHelper";

@Injectable()
export class JournalService {

  progress: Subject<number> = new Subject<number>();

  constructor(private base: BaseService, private http: HttpClient) {
    //
  }

  async getJournals() {
    return await this.base.executeRequest<Journal[]>('/journals', HttpMethod.GET);
  }

  async getJournal(id: number) {
    return await this.base.executeRequest<Journal>(`/journal/${id}`, HttpMethod.GET);
  }

  async getJournalTracks(journal: Journal) {
    return await this.base.executeRequest<void>(`/journal/tracks/${journal.id}`, HttpMethod.GET);
  }

  async getJournalAsUser(id: number, authToken: string) {
    return await this.base.executeRequest<Journal>(`/journal/${id}?auth=${authToken}`, HttpMethod.GET);
  }

  async getJournalByLink(permalink: string) {
    return await this.base.executeRequest<Journal>(`/journal/link/${permalink}`, HttpMethod.GET);
  }

  async getStats(id: number, trackId: number) {
    return await this.base.executeRequest<JournalStats>(`/journal/${id}/stats?track=${trackId}`, HttpMethod.GET);
  }

  async postJournal(journal: Journal) {
    return (await this.base.executeRequest<{ id: number }>('/journal', HttpMethod.POST, journal)).id;
  }

  async putJournal(journal: Journal) {
    return await this.base.executeRequest<Journal>('/journal', HttpMethod.PUT, JournalHelper.createUpdateDto(journal));
  }

  async deleteJournal(journalId: number) {
    return await this.base.executeRequest<void>(`/journal/${journalId}`, HttpMethod.DELETE);
  }

  async deleteAssetsByJournal(journalId: number, assetType: AssetType | undefined = undefined) {
    const path = assetType !== undefined
      ? `/journal/${journalId}/assets?type=${assetType == AssetType.Image ? 'image' : 'video'}`
      : `/journal/${journalId}/assets`;

    return await this.base.executeRequest<void>(path, HttpMethod.DELETE);
  }

  async postAsset(journalId: number, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return new Promise<boolean>((resolve, reject) => {
      this.http.post(`${this.base.url}/journal/asset/${journalId}`, formData, {
        ...this.base.httpOptions,
        reportProgress: true,
        observe: 'events'
      })
        .pipe(
          catchError((err: HttpErrorResponse) => {
            this.base.handleError(err);
            reject();
            return EMPTY;
          })
        )
        .subscribe((event) => {
          switch (event.type) {
            case HttpEventType.Sent:
              return `Uploading file "${file.name}" of size ${file.size}.`;
            case HttpEventType.UploadProgress:
              this.base.progress = event.total ? Math.round(100 * event.loaded / event.total) : 0;
              this.progress.next(this.base.progress);
              return `File "${file.name}" is ${this.base.progress}% uploaded.`;
            case HttpEventType.Response:
              this.base.progress = -1;
              resolve(true);
              return `File "${file.name}" was completely uploaded!`;
            default:
              return `File "${file.name}" surprising upload event: ${event.type}.`;
          }
        });
    });
  }

  async getAsset(assetId: number, quality: "sm" | "md" | "lg" = "sm") {
    return await this.base.executeRequest<Blob>(`/journal/asset/${assetId}?quality=${quality}`, HttpMethod.GET, {}, false, false, 'blob');
  }

  async deleteAsset(assetId: number) {
    return await this.base.executeRequest<void>(`/journal/asset/${assetId}`, HttpMethod.DELETE);
  }

  async getAssetsByEntity(entityId: number) {
    return await this.base.executeRequest<JournalEntityAsset[]>(`/journal/entity/${entityId}/assets`, HttpMethod.GET);
  }

  async deleteEntity(entityId: number) {
    return await this.base.executeRequest<void>(`/journal/entity/${entityId}`, HttpMethod.DELETE);
  }

  async getAppSecret(journalId: number) {
    return await this.base.executeRequest<{ secret: string }>(`/app/secret/${journalId}`, HttpMethod.GET);
  }

  async processImageResolutions(journalId: number) {
    return await this.base.executeRequest<void>(`/job/images/journal`, HttpMethod.POST, {
      id: journalId
    });
  }

  async postThumbnail(assetId: number, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return new Promise<boolean>((resolve, reject) => {
      this.http.post(`${this.base.url}/journal/asset/thumbnail/${assetId}`, formData, {
        ...this.base.httpOptions,
        reportProgress: true,
        observe: 'events'
      })
        .pipe(
          catchError((err: HttpErrorResponse) => {
            this.base.handleError(err);
            reject();
            return EMPTY;
          })
        )
        .subscribe((event) => {
          switch (event.type) {
            case HttpEventType.Sent:
              return `Uploading file "${file.name}" of size ${file.size}.`;
            case HttpEventType.UploadProgress:
              this.base.progress = event.total ? Math.round(100 * event.loaded / event.total) : 0;
              this.progress.next(this.base.progress);
              return `File "${file.name}" is ${this.base.progress}% uploaded.`;
            case HttpEventType.Response:
              this.base.progress = -1;
              resolve(true);
              return `File "${file.name}" was completely uploaded!`;
            default:
              return `File "${file.name}" surprising upload event: ${event.type}.`;
          }
        });
    });
  }

  async deleteThumbnail(assetId: number) {
    return await this.base.executeRequest<void>(`/journal/asset/thumbnail/${assetId}`, HttpMethod.DELETE);
  }

}
