<footer>
  <div class="footer-wrapper offset">
    <div class="main">
      <div class="main-col">
        <img src="assets/logo2.png" alt="Logo">
        <div>
          <label *ngIf="sections !== undefined" [innerText]="getSectionTitleByKey('footer')"></label>
          <span *ngIf="sections !== undefined" [innerHTML]="getSectionTextByKey('footer')"></span>
        </div>
      </div>
      <div class="nav-col">
        <a href="https://dilog.page/impressum" target="_blank">Impressum</a>
        <a href="https://dilog.page/datenschutz" target="_blank">Datenschutz</a>
      </div>
    </div>
    <hr>
    <div class="sub-footer">
      <span>v{{ clientVersion }} © {{ getYear }} - {{ getSectionTitleByKey('footer') ?? 'Logbuch' }} - Alle Rechte vorbehalten.</span>
    </div>
  </div>
</footer>
