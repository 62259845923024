import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-journal-actions',
  standalone: true,
  imports: [],
  templateUrl: './journal-actions.component.html',
  styleUrl: './journal-actions.component.scss'
})
export class JournalActionsComponent {
  @Output() openConnectAppModal = new EventEmitter();
  @Output() openShareModal = new EventEmitter();
  @Output() openPreview = new EventEmitter();
}
