import {Component, Input} from '@angular/core';
import {NgIf} from "@angular/common";
import {TooltipModule} from "primeng/tooltip";
import moment from "moment/moment";
import {Journal} from "../../../models/Journal";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NgIf,
    TooltipModule
  ],
  templateUrl: './journal-preview-header.component.html',
  styleUrl: './journal-preview-header.component.scss'
})
export class JournalPreviewHeaderComponent {
  @Input() flagIcon = 'assets/world.svg';
  @Input() journal?: Journal;

  getBeginEnd() {
    if (this.journal === undefined || this.journal.journals.length <= 1) {
      return;
    }
    const beginDate = this.journal?.journals[0].date;
    const endDate = this.journal?.journals[this.journal.journals.length - 1].date;

    return moment(new Date(beginDate)).format('DD.MM') + ' bis ' + moment(new Date(endDate)).format('DD.MM.YYYY');
  }

}
