<div class="container">
  <app-map *ngIf="journal !== undefined" [readonly]="journal!.readonly" [journalId]="journal!.id"
           (onExit)="onExit.emit()"/>
  <app-header *ngIf="journal !== undefined" [journal]="journal" [flagIcon]="flagIcon"/>
  <div *ngIf="journal !== undefined && journal!.crew !== undefined" class="crew offset">
    <app-journal-crew [crew]="journal!.crew" [readonly]="true"/>
  </div>
  <app-journal-entity-list *ngIf="journal !== undefined" [entities]="entities"
                           (imageClickEvent)="imageClick($event.index, $event.entity)"/>
</div>
