<div class="container">
  <div *ngFor="let asset of sortedAssets" class="row">
    <img *ngIf="previewService.isImage(asset)" (click)="onAssetClick.emit(asset)" [src]="getAssetUrl(asset)">
    <img *ngIf="previewService.isVideo(asset)" (click)="onAssetClick.emit(asset)"
         [src]="asset.thumbnail?.secret != undefined ? getAssetUrl(asset.thumbnail!) : 'assets/play-1873546_640.png'">
    <div class="type">
      <span>Typ:</span>
      <div *ngIf="previewService.isImage(asset)">
        <span>Foto</span>
        <i class="pi pi-image"></i>
      </div>
      <div *ngIf="previewService.isVideo(asset)">
        <span>Video</span>
        <i class="pi pi-video"></i>
      </div>
    </div>
    <div *ngIf="previewService.isImage(asset)" class="uploadedAt">
      <i class="pi pi-calendar"></i>
      <span>{{ asset.date !== undefined ? getDate(asset.date) : '-/-' }} Uhr</span>
    </div>
    <div class="actions">
      <i *ngIf="previewService.isVideo(asset)" (click)="onAssetPutThumbnail.emit(asset)" class="pi pi-image"></i>
      <i (click)="onAssetDelete.emit(asset)" class="pi pi-trash"></i>
    </div>
  </div>
</div>
