<div class="header">
  <div class="title">
    <div class="img">
      <img [src]="flagIcon">
    </div>
    <div>
      <label>
        {{ journal?.title ?? 'unbenannt' }} ({{ journal?.revier ?? 'kein Revier angegeben' }})
      </label>
    </div>
  </div>
  <div class="details">
    <div>
      <img src="assets/sailing-boat.png">
      <span>{{ journal?.boatName ?? 'kein Schiffsname' }} ({{ journal?.boatType ?? 'kein Type angegeben' }})</span>
    </div>
    <div *ngIf="journal?.miles != null">
      <i class="pi pi-share-alt"></i>
      <span pTooltip="Strecke in nautischen Meilen"
            tooltipPosition="bottom">{{ journal?.miles }} NM</span>
    </div>
    <div>
      <i class="pi pi-calendar"></i>
      <span [innerText]="getBeginEnd() ?? '-/-'"></span>
    </div>
  </div>
</div>
