import {Injectable} from "@angular/core";
import {BaseService, HttpMethod} from "./base.service";

@Injectable()
export class PaymentService {

  constructor(private base: BaseService) {
    //
  }

  async initMembershipPayment(membershipId: number) {
    return await this.base.executeRequest<{ client_secret: string }>(`/membership/${membershipId}/pay`, HttpMethod.GET);
  }

}
