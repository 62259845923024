<div *ngIf="service.visibility" class="modal-wrapper">
  <div class="modal-container">
    <div class="title">
      Sortierung
    </div>
    <div class="list">
      <div *ngFor="let item of service.sortDescriptions" [innerText]="item"></div>
    </div>
  </div>
</div>
