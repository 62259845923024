<div class="login-wrapper"
     style="background-image: url('assets/bg/compass-7933587_1920.png');">
  <div class="wrapper-overlay">
    <div class="card-wrapper">
      <div class="card head">
        <span>Logbuch (Anmeldung benötigt)</span>
      </div>
      <div class="row responsive">
        <div class="card">
          <div *ngIf="selectedLoginState == LoginState.KNOWN_USER" class="known-email">
            <i class="pi pi-user"></i>
            <label>{{ email }}</label>
            <span>Willkommen, bitte bestätige kurz deine Identität um fortzufahren! Dazu stehenden dir folgende Optionen zur Verfügung.</span>
          </div>
          <p-floatLabel class="p-fluid"
                        *ngIf="selectedLoginState != LoginState.KNOWN_USER">
            <input id="login" type="email" pInputText [(ngModel)]="email"/>
            <label for="login">Deine E-Mail</label>
          </p-floatLabel>
          <p-floatLabel
            *ngIf="selectedLoginState == LoginState.RESET_PASSWORD"
            class="p-fluid">
            <input id="securityCode" type="password" pInputText [(ngModel)]="securityCode"/>
            <label for="securityCode">Sicherheitscode</label>
          </p-floatLabel>
          <p-floatLabel
            *ngIf="selectedLoginState == LoginState.RESET_PASSWORD || showPasswordField"
            class="p-fluid">
            <input id="password" type="password" pInputText [(ngModel)]="password"/>
            <label for="password">Passwort</label>
          </p-floatLabel>
          <p-floatLabel
            *ngIf="selectedLoginState == LoginState.REGISTER || selectedLoginState == LoginState.RESET_PASSWORD"
            class="p-fluid">
            <input id="password2" type="password" pInputText [(ngModel)]="confirmPassword"/>
            <label for="password2">Passwort wiederholen</label>
          </p-floatLabel>
          <div class="row responsive" style="justify-content: center; gap: 1rem">
            <p-button *ngIf="selectedLoginState == LoginState.LOGIN"
                      (onClick)="confirmMail()" [raised]="true"
                      icon="pi pi-sign-in" class="auto-width"
                      [size]="'small'" label="Weiter"></p-button>
            <p-button *ngIf="selectedLoginState == LoginState.RESET_PASSWORD" (onClick)="resetPassword()"
                      [raised]="true"
                      icon="pi pi-key" class="auto-width"
                      [size]="'small'" label="Übernehmen"></p-button>
            <p-button *ngIf="selectedLoginState == LoginState.FORGOT_PASSWORD" (onClick)="forgotPassword()"
                      [raised]="true" class="auto-width"
                      icon="pi pi-key" label="Überprüfen"></p-button>
            <p-button *ngIf="selectedLoginState == LoginState.KNOWN_USER && !showPasswordField"
                      (onClick)="showPasswordField = !showPasswordField" [raised]="true" class="auto-width"
                      icon="pi pi-key" label="Passwort"></p-button>
            <p-button *ngIf="selectedLoginState == LoginState.KNOWN_USER && showPasswordField"
                      (onClick)="login()" [raised]="true" class="auto-width"
                      icon="pi pi-key" label="Anmelden"></p-button>
            <p-button *ngIf="selectedLoginState == LoginState.KNOWN_USER" class="auto-width"
                      (onClick)="loginWithFido2(); showPasswordField = false" [raised]="true"
                      icon="pi pi-wallet" label="Passkey"></p-button>
            <p-button *ngIf="selectedLoginState == LoginState.KNOWN_USER" class="auto-width"
                      (onClick)="requestLoginWithMail(); showPasswordField = false" [raised]="true"
                      icon="pi pi-at" label="E-Mail"></p-button>
          </div>
          <div class="row" style="justify-content: center;">
            <p-button *ngIf="selectedLoginState == LoginState.PASSWORD"
                      label="Passwort vergessen?" class="auto-width"
                      (onClick)="selectedLoginState = LoginState.FORGOT_PASSWORD"
                      [style]="{'white-space': 'nowrap'}" [text]="true"
                      severity="secondary"></p-button>
            <p-button
              *ngIf="selectedLoginState == LoginState.FORGOT_PASSWORD || selectedLoginState == LoginState.RESET_PASSWORD"
              label="Zurück" class="auto-width"
              (onClick)="ngOnInit()"
              [style]="{'white-space': 'nowrap'}" [text]="true"
              severity="secondary"></p-button>
            <p-button *ngIf="selectedLoginState != LoginState.LOGIN"
                      label="Mit anderem Konto anmelden?" class="auto-width"
                      (onClick)="selectedLoginState = LoginState.LOGIN"
                      [style]="{'white-space': 'nowrap'}" [text]="true"
                      severity="secondary"></p-button>
          </div>
        </div>
        <!-- <div class="card bg">
          <div class="title">
            VDR
          </div>
          <div class="sub-title">
            Dein elektronisches Logbuch, bitte melde dich an oder erstelle dir ein kostenloses Konto, um fortzufahren.
          </div>
          <div class="row" style="width: auto;">
            <p-button label="Mit anderem Konto anmelden?" [size]="'small'" [text]="true"
                      *ngIf="selectedLoginState == LoginState.KNOWN_USER"
                      (onClick)="reset()" severity="info"></p-button>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
