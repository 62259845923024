<div class="login-wrapper">
  <div class="card">
    <div class="card-title">
      <span>Datei-Upload</span>
    </div>
    <div *ngIf="obj?.id != null" class="form-group file-area">
      <input (change)="filesSelected($event)" [multiple]="true" type="file" name="images" id="images"/>
      <div class="file-dummy">
        <div class="placeholder" *ngIf="files.length <= 0">keine Dateien ausgewählt</div>
        <div class="file" *ngFor="let file of files">
          <div [innerText]="file.file.name"></div>
          <div class="progress">{{ file.progress }} %</div>
        </div>
      </div>
    </div>
    <div *ngIf="loader" class="loader">
      <div class="lds-dual-ring"></div>
    </div>
    <div *ngIf="!loader" class="actions">
      <button (click)="upload()" [disabled]="files.length == 0" type="button">Hochladen</button>
      <button (click)="close()" type="button" class="danger">Abbrechen</button>
    </div>
  </div>
</div>
