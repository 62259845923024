import {NgModule} from "@angular/core";
import {
  AuthenticationResponseJSON,
  PublicKeyCredentialCreationOptionsJSON,
  PublicKeyCredentialRequestOptionsJSON,
  RegistrationResponseJSON
} from "@simplewebauthn/typescript-types";
import {startAuthentication, startRegistration} from "@simplewebauthn/browser";
import {Session} from "../models/Session";
import {BaseService, HttpMethod} from "./base.service";
import {WebAuthnRegistration} from "../models/WebAuthnRegistration";

@NgModule()
export class SettingsService {

  constructor(private base: BaseService) {
    //
  }

  getSetupItems() {
    return this.base.executeRequest<{
      name: string,
      value: string | number | boolean
    }[]>('/admin/setup/items', HttpMethod.GET);
  }

  saveSetupItem(key: string, value: string, type: string) {
    return this.base.executeRequest<void>('/admin/setup/item', HttpMethod.PATCH, {
      key,
      value,
      type
    });
  }

}
