<div class="actions">
  <div (click)="openConnectAppModal.emit()" class="action">
    <div>
      <i class="pi pi-camera"></i>
      <label>Connect-App</label>
      <i class="pi pi-expand"></i>
    </div>
    <span>
      Nutze unsere App, um deine Fotos und Videos automatisch zu dieser Reise in das Logbuch zu übernehmen.
    </span>
  </div>
  <div (click)="openShareModal.emit()" class="action">
    <div>
      <i class="pi pi-share-alt"></i>
      <label>Teilen</label>
      <i class="pi pi-expand"></i>
    </div>
    <span>
      Teile den Törn-Bericht mit Familie und Freunden, indem du dir einen Link generierst, über der dieser Törn aufgerufen werden kann.
    </span>
  </div>
  <div (click)="openPreview.emit()" class="action">
    <div>
      <i class="pi pi-eye"></i>
      <label>Vorschau</label>
      <i class="pi pi-external-link"></i>
    </div>
    <span>
      Öffne diesen Beitrag in der Vorschau, um zu sehen, wie andere Personen diesen Logbuch-Eintrag sehen.
    </span>
  </div>
</div>
