import {Journal} from "./Journal";
import {JournalEntityAsset} from "./JournalEntityAsset";
import {VoiceEntry} from "./VoiceEntry";
import {GalleryItem} from "../classes/GalleryItem";

export class JournalEntity {
  id!: number;
  text!: string;
  date!: Date;
  journal!: Journal;
  assets!: JournalEntityAsset[];
  createdAt!: Date;
  voiceEntries!: VoiceEntry[];

  // Non API properties
  assetsLoaded: boolean = false;
  isVisible = false;
  fullScreen = false;
  selectedAsset?: JournalEntityAsset;
  selectedAssetIndex = 0;
  galleryImages: GalleryItem[] = [];
  displayMore = false;
}
