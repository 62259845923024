import {Component} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";
import {RegistryService} from "../../services/registry.service";
import {AlertModalService} from "../alert-modal/alert-modal.service";
import {LoaderService} from "../loader/loader.service";
import {IssueService} from "../../services/issue.service";
import {InputMaskModule} from "primeng/inputmask";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {ButtonModule} from "primeng/button";

@Component({
  selector: 'app-issue',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    FormsModule,
    InputMaskModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule
  ],
  templateUrl: './issue.component.html',
  styleUrl: './issue.component.scss'
})
export class IssueComponent {

  title = '';
  description = '';

  constructor(public services: RegistryService, private alertService: AlertModalService,
              private loader: LoaderService, private service: IssueService) {
    //
  }

  async save() {
    if (this.title == '' || this.description == '') {
      this.alertService.show('Bitte fülle alle Felder aus, um das Formular abzusenden!');
      return;
    }

    this.loader.visibility = true;

    try {
      await this.services.issue.postIssue(this.title, this.description);
      this.title = '';
      this.description = '';
      this.service.visible = false;
    } catch (e) {
      //
    }

    this.loader.visibility = false;

  }

}
