import {JournalEntity} from "../models/JournalEntity";
import {JournalEntityAsset} from "../models/JournalEntityAsset";

export class GalleryItem {
  constructor(
    public entity: JournalEntity,
    public asset: JournalEntityAsset,
    public itemImageSrc: string,
    public thumbnailImageSrc: string,
    public alt: string,
    public title: string) {
  }
}
