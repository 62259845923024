<div class="col" style="gap: 1rem;">
  <div class="title">
    Deine Crews
  </div>
  <div class="list">
    <div *ngFor="let c of crews" (click)="selectCrew(c)" class="crew-list-item">
      <app-journal-crew [readonly]="true" [crew]="c"/>
    </div>
  </div>

  <div class="add">
    <img src="assets/file.svg" (click)="createBlankCrew()"/>
  </div>
</div>

<app-crew-creator-dialog [crew]="crew" [crewMembers]="crewMembers" [display]="showCrewEditor"
                         (crewDeleted)="crewDeleted($event)" (crewUpdated)="crewUpdated($event)"/>
