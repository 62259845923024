import {Injectable} from "@angular/core";
import {UserDetails} from "../models/UserDetail";
import {BaseService, HttpMethod} from "./base.service";
import {Membership} from "../models/Membership";
import {ActiveMembership} from "../models/ActiveMembership";

@Injectable()
export class MembershipService {

  constructor(private base: BaseService) {
    //
  }

  async getMemberships() {
    return await this.base.executeRequest<Membership[]>(`/memberships`, HttpMethod.GET);
  }

  async getOwnMembership() {
    return await this.base.executeRequest<ActiveMembership>(`/membership`, HttpMethod.GET);
  }

  async getActiveMemberships() {
    return await this.base.executeRequest<ActiveMembership[]>(`/memberships/active`, HttpMethod.GET, {}, false, false, undefined, false);
  }

  async hasActiveMembership(user: UserDetails) {
    return (await this.base.executeRequest<{
      state: boolean
    }>(`/memberships/${user.id}/isActive`, HttpMethod.GET)).state;
  }

  async getActiveMembership(user: UserDetails) {
    return await this.base.executeRequest<ActiveMembership>(`/membership/${user.id}`, HttpMethod.GET);
  }

  async addMembership(user: UserDetails, membership: Membership, expiredAt: Date | null = null) {
    return await this.base.executeRequest<void>(`/membership/add`, HttpMethod.POST, {
      user: user.id,
      membership: membership.id,
      expiredAt
    });
  }

  async revokeMembership(user: UserDetails) {
    return await this.base.executeRequest<void>(`/membership/revoke`, HttpMethod.POST, {
      user: user.id
    });
  }

  async updateMembershipProperties(memberships: Membership[]) {
    return await this.base.executeRequest<void>(`/memberships`, HttpMethod.PATCH, memberships);
  }

}
