import {Injectable} from "@angular/core";
import {JournalEntityAsset} from "../../models/JournalEntityAsset";
import {BaseService} from "../../services/base.service";
import {Subject} from "rxjs";

@Injectable()
export class VideoPlayerService {

  videoSelected = new Subject<void>();

  isReadOnly = true;
  selectedVideo?: JournalEntityAsset;

  constructor(private base: BaseService) {
    //
  }

  getVideoUrl() {
    return `${this.base.url}/journal/asset/${this.selectedVideo!.id}?auth=${this.base.httpOptions.headers.Authorization}`;
  }

  getVideoMimeType(asset: JournalEntityAsset|null = null) {
    switch ((asset == null ? this.selectedVideo! : asset).filename.split('.').pop()) {
      case 'mp4':
        return 'video/mp4';
      case 'mpeg':
        return 'video/mpeg';
      default: // mov
        return 'video/mp4';
    }
  }

}
