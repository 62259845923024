import {Injectable} from '@angular/core';
import {JournalEntityAsset} from "../../models/JournalEntityAsset";
import {SecurityQueryService} from "../securityQuery/securityQuery.service";
import {SecurityQueryResult} from "../securityQuery/securityQuery.component";
import {LoaderService} from "../loader/loader.service";
import {RegistryService} from "../../services/registry.service";
import {CrewMember} from "../../models/CrewMember";
import {Subject} from "rxjs";
import {AlertModalService} from "../alert-modal/alert-modal.service";
import validator from "validator";

@Injectable()
export class CrewEditorService {

  visible: boolean = false;
  role: CrewMemberRole = CrewMemberRole.Crew;
  obj: CrewMember = new CrewMember();

  applied: Subject<void> = new Subject<void>();
  canceled: Subject<void> = new Subject<void>();
  deleted: Subject<void> = new Subject<void>();

  constructor(private alertService: AlertModalService) {
    //
  }

  editOrCreate(crewMember: CrewMember|undefined = undefined) {
    return new Promise<boolean>(resolve => {
      this.visible = true;

      if (crewMember !== undefined) {
        this.obj = crewMember;
      }

      this.applied.subscribe(() => {
        this.visible = false;
        resolve(true);
      });

      this.canceled.subscribe(() => {
        this.visible = false;
        resolve(false);
      });

      this.deleted.subscribe(() => {
        this.visible = false;
        resolve(true);
      });

    });
  }

  apply() {

    if (this.obj.name === undefined || this.obj.name === "") {
      this.alertService.show("Bitte gebe einen Namen ein.");
      return;
    }

    if (this.obj.email != null && this.obj.email != '' && !validator.isEmail(this.obj.email)) {
      this.alertService.show("Bitte gebe eine gültige E-Mail-Adresse ein.");
      return;
    }

    this.applied.next();
  }

  cancel() {
    this.canceled.next();
    this.obj = new CrewMember();
    this.role = CrewMemberRole.Crew;
  }

  async delete() {
    this.obj.name = "";
    this.obj.email = "";
    this.deleted.next();
  }

}

export enum CrewMemberRole {
  Skipper = "Skipper",
  CoSkipper = "CoSkipper",
  Navigator = "Navigator",
  Smutje = "Smutje",
  Crew = "Crew",
}
