<div class="wrapper">
  <div class="title">
    SignalK Key - Management
  </div>
  <p-progressBar *ngIf="keys == undefined"
                 mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
  <div *ngIf="keys !== undefined" class="data-view">
    <div *ngFor="let item of keys; let i = index" class="data-view-card"
         [ngClass]="isEven(i) ? 'dark-row' : ''">
      <div class="data-view-item">
        <label>Identifier</label>
        <div (click)="editMode = true">{{ item.identifier }}</div>
      </div>
      <div class="data-view-item">
        <label>Beschreibung</label>
        <div *ngIf="!editMode" (click)="editMode = true">{{ item.description }}</div>
        <input class="p-fluid" pInputText *ngIf="editMode" (keydown)="leaveEditMode($event, item)"
               [(ngModel)]="item.description"
               type="text">
      </div>
    </div>
  </div>
</div>

<app-gateway-management/>
