import {inject} from "@angular/core";
import {Router} from "@angular/router";
import {AuthService} from "../../services/auth.Service";

export const authGuard = (async () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  try {
    await authService.checkSession();
    return true;
  } catch (e) {
    await router.navigate(['/login']);
    return false;
  }
});
