<div *ngIf="selectedTrack !== undefined" class="track-overlay">
  <div class="close" (click)="selectedTrack = undefined">
    <div class="close-inner">
      <img src="assets/close.svg">
    </div>
  </div>
  <div class="track-overlay-inner">
    <app-track [trackId]="selectedTrack!.id" [gatewayId]="selectedTrack!.gateway.id"/>
  </div>
</div>
