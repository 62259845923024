import {Injectable} from "@angular/core";
import {BaseService, HttpMethod} from "./base.service";

@Injectable()
export class InfoService {

  constructor(private base: BaseService) {
    //
  }

  async getInfo() {
    return await this.base.executeRequest<Info>('/test', HttpMethod.GET);
  }

}

export interface Info {
  name: string;
  version: string;
}
