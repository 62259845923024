<div class="container">
  <section>
    <div class="profile-title">Persönliche Daten</div>
    <p-messages [(value)]="messages"
                [enableService]="false"
                [closable]="false"/>
    <span *ngIf="user !== undefined && user!.user !== undefined"
          class="p-float-label p-fluid">
      <input pInputText id="firstname" [(ngModel)]="user!.firstname">
      <label for="firstname">Vorname</label>
    </span>
    <span *ngIf="user !== undefined && user!.user !== undefined"
          class="p-float-label p-fluid">
      <input pInputText id="lastname" [(ngModel)]="user!.lastname">
      <label for="lastname">Nachname</label>
    </span>
    <div *ngIf="user !== undefined && user!.user !== undefined" class="row" style="gap: 0.5rem;">
      <span class="p-float-label p-fluid" [style]="{width: '100%'}">
        <input disabled pInputText id="email" [(ngModel)]="user!.user!.email">
        <label for="email">E-Mail Adresse</label>
      </span>
      <p-button (onClick)="changeMailDisplayed = true" [style]="{width: '3rem'}"
                *ngIf="phoneVerified === true" icon="pi pi-pen-to-square"></p-button>
    </div>
    <div *ngIf="user !== undefined && user!.user !== undefined" class="row" style="gap: 0.5rem;">
      <span class="p-float-label p-fluid" [style]="{width: '100%'}">
        <input pInputText id="tel" [(ngModel)]="user!.phone" [disabled]="phoneVerified == true">
        <label for="tel">Telefonnummer</label>
      </span>
      <span *ngIf="phoneVerificationCodeRequested || phoneDeleteRequested" class="p-float-label p-fluid"
            [style]="{width: '100%'}">
        <input pInputText id="telVerifyCode" [(ngModel)]="phoneVerificationCode">
        <label for="tel">Verifizierungscode</label>
      </span>
      <p-button (onClick)="requestPhoneVerificationCode()" [style]="{width: 'auto'}"
                *ngIf="phoneVerified === false && phoneExists"
                label="Verifizieren" icon="pi pi-send"></p-button>
      <p-button (onClick)="deletePhoneRequest()" [style]="{width: '3rem'}"
                severity="danger"
                *ngIf="phoneVerified === true" icon="pi pi-trash"></p-button>
    </div>
    <p-button (onClick)="updateData()"
              *ngIf="user !== undefined && user!.user !== undefined"
              label="Übernehmen" icon="pi pi-check"></p-button>
    <p-progressBar *ngIf="user == undefined"
                   mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
  </section>
</div>

<div *ngIf="user?.user != null && changeMailDisplayed" class="change-mail-wrapper">
  <div class="change-mail-container">
    <div class="title">
      E-Mail Adresse ändern
    </div>
    <div class="form-row">
      <div class="p-float-label p-fluid">
        <input disabled pInputText id="change-mail-current" [(ngModel)]="user!.user!.email">
        <label for="change-mail-current">Aktuelle E-Mail Adresse</label>
      </div>
      <i class="pi pi-arrow-right"></i>
      <div class="p-float-label p-fluid">
        <input [disabled]="changeMailOldAddressCodeRequested" [(ngModel)]="changeMailNewAddress" pInputText
               id="change-mail-new">
        <label for="change-mail-new">Neue E-Mail Adresse</label>
      </div>
      <p-button (onClick)="startChangeMail()" [disabled]="changeMailOldAddressCodeRequested" label="Start"
                icon="pi pi-send"></p-button>
    </div>
    <div class="form-row" style="justify-content: space-evenly">
      <div class="col" style="width: auto;">
        <div class="input-otp-label">Sicherheitscode (Ursprüngliche E-Mail)</div>
        <div class="input-otp-desc">Gebe an dieser Stelle den Code ein, welcher an deine <b>ursprüngliche</b> E-Mail
          gesendet
          wurde.4
        </div>
        <p-inputOtp [(ngModel)]="changeMailOldAddressCode" [length]="6"
                    style="gap: 0">
          <ng-template pTemplate="input" let-token1 let-events="events" let-index="index">
            <input (paste)="paste($event, 'old')" [disabled]="!changeMailOldAddressCodeRequested" pInputText
                   type="text"
                   [maxLength]="1" (input)="events.input($event)"
                   [attr.value]="token1" class="custom-otp-input otp-input"/>
            <div *ngIf="index === 3" class="px-3">
              <i class="pi pi-minus"></i>
            </div>
          </ng-template>
        </p-inputOtp>
      </div>
      <div class="col" style="width: auto;">
        <div class="input-otp-label">Sicherheitscode (Neue E-Mail)</div>
        <div class="input-otp-desc">Gebe an dieser Stelle den Code ein, welcher an deine <b>neue</b> E-Mail gesendet
          wurde.
        </div>
        <p-inputOtp [(ngModel)]="changeMailNewAddressCode" [length]="6"
                    style="gap: 0">
          <ng-template pTemplate="input" let-token2 let-events="events" let-index="index">
            <input (paste)="paste($event, 'new')" [disabled]="!changeMailOldAddressCodeRequested" pInputText
                   type="text"
                   [maxLength]="1" (input)="events.input($event)"
                   [attr.value]="token2" class="custom-otp-input otp-input"/>
            <div *ngIf="index === 3" class="px-3">
              <i class="pi pi-minus"></i>
            </div>
          </ng-template>
        </p-inputOtp>
      </div>
    </div>
    <div class="form-row">
      <p-button (onClick)="closeChangeMail()" label="Abbrechen" severity="danger" icon="pi pi-undo"></p-button>
      <p-button (onClick)="confirmChangeMail()" [disabled]="!changeMailOldAddressCodeRequested" label="Überprüfen"
                icon="pi pi-sync"></p-button>
    </div>
  </div>
</div>
