import {Component, OnInit} from '@angular/core';
import {FloatLabelModule} from "primeng/floatlabel";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {PortfolioSection} from "../../../models/PortfolioSection";
import {RegistryService} from "../../../services/registry.service";
import {LoaderService} from "../../loader/loader.service";
import {FaqItem} from "../../../models/FaqItem";
import {NgForOf, NgIf} from "@angular/common";
import {EditorModule} from "primeng/editor";

@Component({
  selector: 'app-cms-management',
  standalone: true,
  imports: [
    FloatLabelModule,
    FormsModule,
    InputTextModule,
    NgForOf,
    EditorModule,
    NgIf
  ],
  templateUrl: './cms-management.component.html',
  styleUrl: './cms-management.component.scss'
})
export class CmsManagementComponent implements OnInit {

  sections: PortfolioSection[] = [];
  faqItems: FaqItem[] = [];

  constructor(private services: RegistryService,
              private loader: LoaderService) {
  }

  async ngOnInit() {
    try {
      this.loader.visibility = true;
      this.sections = await this.services.cms.getSections();
      this.faqItems = await this.services.cms.getFaqQuestions();
    } finally {
      this.loader.visibility = false;
    }
  }

  async save(item: PortfolioSection) {
    try {
      this.loader.visibility = true;
      await this.services.cms.updateSection(item);
    } finally {
      this.loader.visibility = false;
    }
  }

  async saveFaq(item: FaqItem) {
    try {
      this.loader.visibility = true;

      if (item.id) {
        await this.services.cms.updateFaqQuestion(item);
      } else {
        if (item.question !== '' && item.answer !== '' && item.question !== null && item.answer !== null) {
          await this.services.cms.createFaqQuestion(item);
          this.faqItems = await this.services.cms.getFaqQuestions();
        }
      }
    } finally {
      this.loader.visibility = false;
    }
  }

  async deleteFaq(item: FaqItem) {
    if (item.id === null) {
      this.faqItems = this.faqItems.filter(x => x !== item);
      return;
    }
    try {
      this.loader.visibility = true;
      await this.services.cms.deleteFaqQuestion(item.id!);
      this.faqItems = await this.services.cms.getFaqQuestions();
    } finally {
      this.loader.visibility = false;
    }
  }

  createBlankQuestion() {
    this.faqItems.push(new FaqItem());
  }

}
