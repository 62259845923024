<div *ngIf="service.visible" class="overlay">
  <div class="container">
    <div class="title">
      Verifizierung benötigt!
    </div>
    <p-message severity="info"
               text="Um diese Aktion durchzuführen ist es notwendig, dass du deine aktuelle Sitzung durch einen zweiten Faktor (z.B. deine E-Mail Adresse) verifizierst."></p-message>
    <div class="token-input">
      <div class="desc">Gebe nachfolgend den Code ein, der an deine E-Mail Adresse geschickt wurde.</div>
      <p-inputOtp [(ngModel)]="service.token" [length]="6" style="gap: 0">
        <ng-template pTemplate="input" let-token let-events="events" let-index="index">
          <input (paste)="paste($event)" pInputText type="text" [maxLength]="1" (input)="events.input($event)"
                 (keydown)="events.keydown($event)" [attr.value]="token" class="custom-otp-input"/>
          <div *ngIf="index === 3" class="px-3">
            <i class="pi pi-minus"></i>
          </div>
        </ng-template>
      </p-inputOtp>
      <div class="buttons">
        <p-button *ngIf="service.appService.userDetails?.phone" (onClick)="service.requestCode(true, true)"
                  [style]="{'text-transform': 'uppercase'}"
                  label="SMS Erhalten"
                  [link]="true"></p-button>
        <p-button (onClick)="service.requestCode(true)" [style]="{'text-transform': 'uppercase'}" label="Neu anfondern"
                  [link]="true"></p-button>
        <p-button (onClick)="service.checkToken()" label="Überprüfen"></p-button>
      </div>
      <div class="buttons">
        <p-button (onClick)="close()" [style]="{'font-size': '0.9rem', 'color': '#8a8a8a'}" label="Abbrechen"
                  [link]="true"></p-button>
      </div>
    </div>
  </div>
</div>
