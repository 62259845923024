<div *ngIf="showAppSecret" class="overlay-wrapper">
  <div class="app-connect-overlay animate__animated animate__fadeIn">
    <qrcode *ngIf="qrCodeData !== undefined"
            [qrdata]="qrCodeData"
            [width]="135"
            [margin]="4"
            colorDark="#fff"
            colorLight="#495676"
    ></qrcode>
    <div>
      <div class="head">
        <label>Connect-App</label>
        <i (click)="close.emit()" style="font-size: 1.2rem;" class="pi pi-times-circle"></i>
      </div>
      <span>Benutze unsere Connect-App, um Fotos & Videos direkt aus deiner Mediathek hinzuzufügen.</span>
      <div class="links">
        <a target="_blank" href="https://apps.apple.com/de/app/vdr/id6478563377">
          <img src="assets/appstore.png">
        </a>
      </div>
    </div>
  </div>

</div>
