import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Coordinate} from "ol/coordinate";
import {Loader} from "@googlemaps/js-api-loader";
import {Easing, Tween, update} from "@tweenjs/tween.js";
import {Journal} from "../../../models/Journal";
import {PicturePreviewService} from "../../picture-preview/picture-preview.service";
import {MapType} from "../../../classes/MapType";
import {LogPrefix} from "../../app.config";
import {MapService} from "../../map/map.service";
import {AppService} from "../../app.service";

declare const mapkit: any;

interface ICoordinate {
  latitude: number;
  longitude: number;
}

interface BoundingBox {
  minLat: number;
  maxLat: number;
  minLon: number;
  maxLon: number;
}

@Component({
  selector: 'app-journal-map-overview',
  standalone: true,
  imports: [],
  templateUrl: './journal-map-overview.component.html',
  styleUrl: './journal-map-overview.component.scss'
})
export class JournalMapOverviewComponent implements AfterViewInit {

  @Input() journal?: Journal;

  mapLoaded = false;

  mapKitMap?: any;
  gMap: any;

  constructor(private previewService: PicturePreviewService,
              private mapService: MapService) {
  }

  ngAfterViewInit() {
    this.load().then();
  }

  async load() {
    while (this.journal === undefined) {
      await new Promise(resolve => setTimeout(resolve, 100));
      console.debug(`${LogPrefix.I} Map-Component - Waiting for journal to be loaded...`);
    }

    await this.mapService.loadKey();

    if (this.mapService.mapType == MapType.AppleMaps) {
      await this.initAppleMap();
    } else if (this.mapService.mapType == MapType.Google) {
      await this.initGoogleMap();
    }
  }

  getFirstLatestAsset() {
    const firstAsset = this.journal?.journals
      .filter(x => x.assets != null && x.assets.length > 0)
      .map(x => x.assets[0])
      .find(x => this.previewService.isImage(x) && x.latitude != -1 && x.longitude != -1);
    const latestAsset = this.journal?.journals
      .filter(x => x.assets != null && x.assets.length > 1)
      .map(x => x.assets[x.assets.length - 1])
      .find(x => this.previewService.isImage(x) && x.latitude != -1 && x.longitude != -1);
    return {firstAsset, latestAsset};
  }

  async initAppleMap() {
    const coords: ICoordinate[] = [];

    for (const entity of this.journal?.journals ?? []) {
      for (const asset of entity.assets) {
        if (this.previewService.isImage(asset) &&
          asset.latitude !== -1 &&
          asset.longitude !== -1) {
          coords.push({
            latitude: asset.latitude,
            longitude: asset.longitude
          });
          break;
        }
      }
    }

    if (!this.mapService.mapkitLibLoaded) {
      await this.mapService.preloadMapKit();
    }

    try {
      this.mapKitMap = new mapkit.Map("map-journal");
      const MarkerAnnotation = mapkit.MarkerAnnotation;
      const annotations: any[] = [];

      // Fallback für leere Koordinatenliste
      if (coords.length === 0) {
        const defaultCoord: ICoordinate = {
          latitude: 49.0353627,
          longitude: -8.7564463
        };
        coords.push(defaultCoord);
      }

      for (const coord of coords) {
        annotations.push(
          new MarkerAnnotation(
            new mapkit.Coordinate(coord.latitude, coord.longitude),
            { color: "#ec3b3b" }
          )
        );
      }

      const bounds = this.calculateBoundingBox(coords);

      const center = {
        latitude: (bounds.minLat + bounds.maxLat) / 2,
        longitude: (bounds.minLon + bounds.maxLon) / 2
      };

      // Span (Zoom) basierend auf der Ausdehnung berechnen
      const latSpan = Math.abs(bounds.maxLat - bounds.minLat) * 1.2; // 20% Padding
      const lonSpan = Math.abs(bounds.maxLon - bounds.minLon) * 1.2; // 20% Padding
      const span = Math.max(latSpan, lonSpan);

      // Region erstellen und setzen
      const coordinate = new mapkit.Coordinate(center.latitude, center.longitude);
      const coordinateSpan = new mapkit.CoordinateSpan(span, span);
      const region = new mapkit.CoordinateRegion(coordinate, coordinateSpan);

      // Minimale und maximale Zoom-Grenzen setzen
      this.mapKitMap.cameraZoomRange = new mapkit.CameraZoomRange(
        2000,    // Minimale Kameradistanz (max zoom)
        2000000  // Maximale Kameradistanz (min zoom)
      );

      // Marker und Region anwenden
      this.mapKitMap.showItems(annotations);
      this.mapKitMap.setRegionAnimated(region);
      this.mapLoaded = true;

    } catch (error) {
      console.error('Error initializing Apple Maps:', error);
    }
  }

  /**
   * Berechnet die Bounding Box für eine Liste von Koordinaten
   */
  private calculateBoundingBox(coords: ICoordinate[]): BoundingBox {
    if (coords.length === 0) {
      throw new Error('No coordinates provided');
    }

    const bounds: BoundingBox = {
      minLat: coords[0].latitude,
      maxLat: coords[0].latitude,
      minLon: coords[0].longitude,
      maxLon: coords[0].longitude
    };

    for (const coord of coords) {
      bounds.minLat = Math.min(bounds.minLat, coord.latitude);
      bounds.maxLat = Math.max(bounds.maxLat, coord.latitude);
      bounds.minLon = Math.min(bounds.minLon, coord.longitude);
      bounds.maxLon = Math.max(bounds.maxLon, coord.longitude);
    }

    // Wenn nur ein Punkt vorhanden ist, erstellen wir eine künstliche Box
    if (bounds.minLat === bounds.maxLat && bounds.minLon === bounds.maxLon) {
      const padding = 0.1; // ~11km bei Breitengrad 0
      bounds.minLat -= padding;
      bounds.maxLat += padding;
      bounds.minLon -= padding;
      bounds.maxLon += padding;
    }

    return bounds;
  }

  /**
   * Optional: Hilfsmethode zum Berechnen des optimalen Zooms für zwei Punkte
   */
  private calculateOptimalZoom(bounds: BoundingBox): number {
    const EARTH_RADIUS = 6371; // Erdradius in km
    const latDistance = (bounds.maxLat - bounds.minLat) * Math.PI / 180;
    const lonDistance = (bounds.maxLon - bounds.minLon) * Math.PI / 180;

    // Ungefähre Distanz zwischen den entferntesten Punkten
    const latDist = latDistance * EARTH_RADIUS;
    const lonDist = Math.cos(bounds.minLat * Math.PI / 180) * lonDistance * EARTH_RADIUS;

    const maxDistance = Math.max(latDist, lonDist);

    // Zoom-Level basierend auf der Distanz
    if (maxDistance < 1) return 0.01;      // Sehr nah
    if (maxDistance < 5) return 0.05;      // Stadt
    if (maxDistance < 20) return 0.2;      // Großstadt
    if (maxDistance < 100) return 1;       // Region
    if (maxDistance < 500) return 5;       // Land
    if (maxDistance < 2000) return 20;     // Kontinent
    return 50;                             // Welt
  }

  async initGoogleMap(): Promise<void> {

    const assets = this.getFirstLatestAsset();

    if (assets.firstAsset == undefined) {
      return;
    }

    const loader = new Loader({
      apiKey: this.mapService.apiToken!,
      version: "weekly",
      libraries: ["marker"]
    });

    const google = await loader.load();

    //@ts-ignore
    const {Map} = await google.maps.importLibrary("maps");

    //@ts-ignore
    const cameraOptions: google.maps.CameraOptions = {
      tilt: 0,
      heading: 0,
      zoom: 1,
      center: {lat: assets.firstAsset.latitude, lng: assets.firstAsset.longitude},
    };

    const mapOptions = {
      ...cameraOptions,
    };

    this.gMap = new Map(document.getElementById("map-journal") as HTMLElement, {
      streetViewControl: false,
      // mapTypeControl: false,
      mapId: 'JOURNAL_OVERVIEW_MAP',
      mapOptions,
    });

    const bounds = new google.maps.LatLngBounds();

    for (const entity of this.journal?.journals ?? []) {
      for (const asset of entity.assets) {
        if (asset.latitude != -1 && asset.longitude != -1) {
          const marker = new google.maps.marker.AdvancedMarkerElement({
            position: new google.maps.LatLng(asset.latitude, asset.longitude),
            map: this.gMap
          });

          bounds.extend(marker.position);

        }
      }
    }

    // install Tweenjs with npm i @tweenjs/tween.js
    new Tween(cameraOptions) // Create a new tween that modifies 'cameraOptions'.
      .to({zoom: 12}, 7500) // Move to destination in 15 second.
      .easing(Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
      .onUpdate(() => {
        this.gMap.moveCamera(cameraOptions);
      })
      .start()
      .onComplete(() => {

        this.gMap.fitBounds(bounds);

        const listener = google.maps.event.addListener(this.gMap, "idle", () => {
          this.gMap.setZoom(7);
          google.maps.event.removeListener(listener);
        });

      });

    function animate(time: number) {
      requestAnimationFrame(animate);
      update(time);
    }

    requestAnimationFrame(animate);

  }

  setMapCenterToMedian(coordinates: Coordinate[]) {
    const lats = coordinates.map(coord => coord[1]);
    const lons = coordinates.map(coord => coord[0]);

    lats.sort((a, b) => a - b);
    lons.sort((a, b) => a - b);

    const medianLat = lats[Math.floor(lats.length / 2)];
    const medianLon = lons[Math.floor(lons.length / 2)];

    return {lat: medianLat, lon: medianLon};
  }

}
