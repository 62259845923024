<div class="container">
  <section>
    <div class="profile-title">Favorisierte Kartendarstellung</div>
    <div class="map-types">
      <div *ngIf="user !== undefined && user!.user !== undefined" class="map-type">
        <p-radioButton [(ngModel)]="user.mapType" [value]="MapType.AppleMaps" id="apple" name="mapType"></p-radioButton>
        <i class="pi pi-apple"></i>
        <label for="apple">Apple Karten</label>
      </div>
      <div *ngIf="user !== undefined && user!.user !== undefined" class="map-type">
        <p-radioButton [(ngModel)]="user.mapType" [value]="MapType.Google" id="google" name="mapType"></p-radioButton>
        <i class="pi pi-google"></i>
        <label for="google">Google Maps</label>
      </div>
    </div>
    <p-button (onClick)="updateData()"
              *ngIf="user !== undefined && user!.user !== undefined"
              label="Übernehmen" icon="pi pi-check"></p-button>
    <p-progressBar *ngIf="user == undefined"
                   mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
  </section>
</div>
