import {NgModule} from "@angular/core";
import {BaseService, HttpMethod} from "./base.service";
import {Permission} from "../models/Permission";
import {PermissionAssignment} from "../models/PermissionAssignment";
import {PermissionType} from "../classes/PermissionType";

@NgModule()
export class PermissionService {

  constructor(private base: BaseService) {
    //
  }

  private async findPermission(identifier: string) {
    return (await this.getPermissionAssignments()).find(x => x.permission?.identifier == identifier);
  }

  getPermissionAssignments() {
    return this.base.executeRequest<PermissionAssignment[]>('/user/permissions', HttpMethod.GET);
  }

  getPermissions() {
    return this.base.executeRequest<Permission[]>('/permissions', HttpMethod.GET);
  }

  getPermissionsByUserId(userId: number) {
    return this.base.executeRequest<Permission[]>('/permissions/user/' + userId, HttpMethod.GET);
  }

  assignPermissionToUser(user_id: number, permission_id: number, writeable: boolean = false) {
    return this.base.executeRequest<void>('/permissions/user', HttpMethod.POST, {
      user_id, permission_id, writeable
    });
  }

  revokePermission(permission_assignment_id: number) {
    return this.base.executeRequest<void>('/permission/assignment/' + permission_assignment_id, HttpMethod.DELETE);
  }

  async checkReadable(permissionType: PermissionType, tryAsAdmin = true): Promise<boolean> {
    try {
      const permission = await this.findPermission(permissionType)
      return permission != undefined || (tryAsAdmin && await this.isAdmin());
    } catch (e) {
      return false;
    }
  }

  async checkWriteable(permissionType: PermissionType): Promise<boolean> {
    try {
      const isAdmin = await this.isAdmin();
      const permission = await this.findPermission(permissionType)
      if ((permission != undefined && permission.writeable != undefined) || isAdmin) {
        return isAdmin ?? permission?.writeable;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  async isAdmin(): Promise<boolean> {
    return await this.checkReadable(PermissionType.Administrator, false);
  }

}
