<div class="login-wrapper">
  <div class="card">
    <div class="card-title">
      <span>Neuer Törn</span>
    </div>
    <div class="row p-fluid">
      <p-floatLabel>
        <input [(ngModel)]="obj.title" id="title" type="text" pInputText/>
        <label for="title">Titel</label>
      </p-floatLabel>
    </div>
    <div class="row p-fluid">
      <p-floatLabel>
        <p-autoComplete [(ngModel)]="obj.country"
                        (ngModelChange)="countryChanged($event)"
                        [suggestions]="countries"
                        (completeMethod)="loadCountries($event)" id="country">
          <ng-template let-country pTemplate="item">
            <div class="row">
              <img src="{{ country.flags.svg }}"
                   style="width: 24px; height: auto;"/>
              <div>{{ country.translations.deu.common }}</div>
            </div>
          </ng-template>
        </p-autoComplete>
        <label for="country">Land</label>
      </p-floatLabel>
      <p-floatLabel>
        <input id="revier" type="text" pInputText [(ngModel)]="obj.revier"/>
        <label for="revier">Revier</label>
      </p-floatLabel>
    </div>
    <div class="row">
      <p-floatLabel>
        <input id="year" type="text" pInputText [(ngModel)]="obj.year"/>
        <label for="year">Jahr</label>
      </p-floatLabel>
      <p-floatLabel>
        <input id="miles" type="text" pInputText [(ngModel)]="obj.miles"/>
        <label for="miles">Meilen</label>
      </p-floatLabel>
    </div>
    <div class="row">
      <p-floatLabel>
        <input id="boatName" type="text" pInputText [(ngModel)]="obj.boatName"/>
        <label for="boatName">Bootname</label>
      </p-floatLabel>
      <p-floatLabel>
        <input id="boatType" type="text" pInputText [(ngModel)]="obj.boatType"/>
        <label for="boatType">Bootstyp</label>
      </p-floatLabel>
    </div>
    <div class="row">
      <p-floatLabel>
        <p-calendar [(ngModel)]="startAt" [iconDisplay]="'input'" [showIcon]="true" id="startAt"
                    [style]="{width: '100%'}"></p-calendar>
        <label for="startAt">Start</label>
      </p-floatLabel>
      <p-floatLabel>
        <p-calendar [(ngModel)]="endAt" [iconDisplay]="'input'" [showIcon]="true" id="endAt"
                    [style]="{width: '100%'}"></p-calendar>
        <label for="endAt">Ende</label>
      </p-floatLabel>
    </div>
    <p-buttonGroup>
      <p-button (click)="create()" severity="primary" label="Übernehmen" icon="pi pi-check"
                size="small"></p-button>
      <p-button (click)="close()" [outlined]="true" severity="secondary" label="Abbrechen" icon="pi pi-times"
                size="small"></p-button>
      <p-button (click)="showCrewEditorDialog()" [outlined]="true" severity="secondary" label="Crew Hinzufügen"
                icon="pi pi-user-edit"
                size="small"></p-button>
    </p-buttonGroup>
  </div>
</div>

<app-crew-creator-dialog [crew]="crew" [crewMembers]="crewMembers" [display]="showCrewEditor"
                         (crewUpdated)="crewUpdated($event)" (crewCreated)="crewUpdated($event)"
                         (crewDeleted)="deleteCrew()"/>
