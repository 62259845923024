import {Injectable} from "@angular/core";
import {BaseService, HttpMethod} from "./base.service";
import {Track} from "../models/Track";
import {TrackDataKey} from "../models/TrackDataKey";
import {TrackDataUnit} from "../models/TrackDataUnit";
import {JournalStats} from "../classes/JournalStats";
import {TrackRecord} from "../models/TrackRecord";
import {TrackData} from "../models/TrackData";

@Injectable()
export class TracksService {

  constructor(private base: BaseService) {
    //
  }

  async getTracks(page: number, take: number) {
    return await this.base.executeRequest<Track[]>(`/tracks/${page}/${take}`, HttpMethod.GET);
  }

  async getTrack(id: number, receiveRecords: boolean = false) {
    return await this.base.executeRequest<Track>(`/track/${id}?sendData=${receiveRecords.toString()}`, HttpMethod.GET);
  }

  async getTrackDataKeys() {
    return await this.base.executeRequest<TrackDataKey[]>('/trackDataKeys', HttpMethod.GET);
  }

  async updateTrackDataKey(key: TrackDataKey) {
    return await this.base.executeRequest<void>('/trackDataKey', HttpMethod.PATCH);
  }

  async getTrackDataUnits() {
    return await this.base.executeRequest<TrackDataUnit[]>('/track/data/units', HttpMethod.GET);
  }

  async putTrack(track: Track) {
    const copyOfTrack = Object.assign({}, track);
    copyOfTrack.records = [];

    return await this.base.executeRequest<void>('/track', HttpMethod.PUT, copyOfTrack);
  }

  async getStats(id: number,) {
    return await this.base.executeRequest<JournalStats>(`/track/${id}/stats`, HttpMethod.GET);
  }

  async getRecord(id: number) {
    return await this.base.executeRequest<TrackRecord>(`/record/${id}`, HttpMethod.GET);
  }

  async deleteTrack(id: number) {
    return await this.base.executeRequest<void>(`/track/${id}`, HttpMethod.DELETE);
  }

  async getDataByKey(dataKey: TrackDataKey, record: TrackRecord) {
    return await this.base.executeRequest<TrackData>(`/track/data/${record.id}/${dataKey.identifier}`, HttpMethod.GET);
  }

  async setPermalink(journalId: number, permalink: string | null = null) {
    return await this.base.executeRequest<void>(`/journal/link/${journalId}`, HttpMethod.PATCH, {
      permalink
    });
  }

  async deletePermalink(journalId: number) {
    return await this.base.executeRequest<void>(`/journal/link/${journalId}`, HttpMethod.DELETE);
  }

}
