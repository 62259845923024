import {Component, OnInit} from '@angular/core';
import {AppService} from "../app.service";
import {NgClass, NgIf} from "@angular/common";
import validator from 'validator';
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "../loader/loader.service";
import {AlertModalService, AlertType} from "../alert-modal/alert-modal.service";
import {FormsModule} from "@angular/forms";
import {FloatLabelModule} from "primeng/floatlabel";
import {InputTextModule} from "primeng/inputtext";
import {PasswordModule} from "primeng/password";
import {ButtonModule} from "primeng/button";
import {AuthService} from "../../services/auth.Service";
import {CryptoService} from "../../services/crypto.Service";
import {RegistryService} from "../../services/registry.service";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    FormsModule,
    FloatLabelModule,
    InputTextModule,
    PasswordModule,
    ButtonModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {

  selectedLoginState: LoginState = LoginState.LOGIN;

  showPasswordField = false;

  password = '';
  email = '';
  confirmPassword = '';
  securityCode = '';

  passwordError = false;
  emailError = false;
  confirmPasswordError = false;
  securityCodeError = false;

  constructor(private router: Router, private appService: AppService,
              private authService: AuthService, private crypto: CryptoService,
              private loader: LoaderService, private alertService: AlertModalService,
              private services: RegistryService, private route: ActivatedRoute) {
    //
  }

  async ngOnInit() {
    try {
      this.loader.visibility = true;

      const secret = this.route.snapshot.paramMap.get('secret');

      if (secret != null) {
        await this.loginWithMail(secret);
        return;
      }

      const lastUsedEmail = localStorage.getItem('last_used_email');
      if (lastUsedEmail) {
        this.email = lastUsedEmail;
        this.selectedLoginState = LoginState.KNOWN_USER;
      }

      await this.appService.checkAuth();

      while (this.appService.authInProgress) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      if (this.appService.isAuth) {
        await this.router.navigate(['/journal']);
      }
    } finally {
      this.loader.visibility = false;
    }
  }

  async sendPublicKey() {
    this.crypto.setKeys();
    while (this.crypto.clientPubKey == null) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }

    await this.authService.savePubKey(this.crypto.clientPubKey);
  }

  async loginWithFido2() {
    try {
      this.loader.visibility = true;
      const result = await this.services.fido2.authClient(this.email);
      if (result) {
        await this.sendPublicKey();
        this.afterLogin().then();
      }
    } catch (e) {
      this.alertService.show('Die Authentifizierung ist mittels Passkey ist fehlgeschlagen.');
    } finally {
      this.loader.visibility = false;
    }
  }

  get LoginState() {
    return LoginState;
  }

  confirmMail() {
    if (!validator.isEmail(this.email)) {
      this.alertService.show('Die E-Mail-Adresse ist ungültig.');
      return;
    }
    this.selectedLoginState = LoginState.KNOWN_USER;
  }

  async login() {

    this.loader.visibility = true;

    this.emailError = this.email === '' || this.emailError == null || !validator.isEmail(this.email);
    this.passwordError = this.password === '' || this.passwordError == null;

    if (this.emailError) {
      this.alertService.show('Für den Login ist die Angabe einer gültigen E-Mail-Adresse erforderlich.');
      this.loader.visibility = false;
      return;
    }

    try {
      const session = await this.authService.login(this.email, this.password, true);
      localStorage.setItem('sessionID', session.ec ? this.crypto.decryptSingleValue(session.secret) : session.secret);
      this.afterLogin().then();
    } catch (e) {
      this.alertService.show('Die Anmeldedaten sind ungültig.');
      this.emailError = true;
      this.passwordError = true;
    } finally {
      this.password = '';
      this.loader.visibility = false;
    }

  }

  async afterLogin() {
    await this.appService.checkAuth();
    await this.appService.updateStats();
    localStorage.setItem('last_used_email', this.email.toLowerCase());
    this.email = '';
    await this.router.navigate(['/journal']);
    this.loader.visibility = false;
  }

  async register() {

    this.loader.visibility = true;

    this.emailError = this.email === '' || this.emailError == null || !validator.isEmail(this.email);
    this.passwordError = this.password === '' || this.passwordError == null;
    this.confirmPasswordError = this.confirmPassword === '' || this.confirmPassword == null;

    if (this.password !== this.confirmPassword) {
      this.alertService.show('Die Passwörter stimmen nicht überein.');
      this.passwordError = true;
      this.confirmPasswordError = true;
      this.loader.visibility = false;
      return;
    }

    if (this.emailError || this.passwordError || this.confirmPasswordError) {
      this.alertService.show('Es sind Validierungsfehler aufgetreten, bitte überprüfe deine Eingaben.');
      this.loader.visibility = false;
      return;
    }

    try {
      await this.authService.register(this.email, this.password);
      this.selectedLoginState = LoginState.LOGIN;
      this.loader.visibility = false;
    } catch (e) {
      this.loader.visibility = false;
    }

  }

  async forgotPassword() {

    this.loader.visibility = true;

    this.emailError = this.email === '' || this.emailError == null || !validator.isEmail(this.email);

    if (this.emailError) {
      this.alertService.show('Es sind Validierungsfehler aufgetreten, bitte überprüfe deine Eingaben.');
      this.loader.visibility = false;
      return;
    }

    try {
      await this.authService.passwordResetRequest(this.email);
      this.selectedLoginState = LoginState.RESET_PASSWORD;
    } catch (e) {
      this.alertService.show('Es ist ein Fehler beim Zurücksetzen des Passworts aufgetreten, bitte überprüfe deine Eingaben.');
      this.emailError = true;
    }

    this.loader.visibility = false;

  }

  reset() {
    localStorage.clear();
    window.location.reload();
  }

  async resetPassword() {

    this.loader.visibility = true;

    this.passwordError = this.password === '' || this.passwordError == null;
    this.confirmPasswordError = this.confirmPassword === '' || this.confirmPassword == null;
    this.securityCodeError = this.securityCode === '' || this.securityCode == null || !validator.isNumeric(this.securityCode);

    if (this.password !== this.confirmPassword) {
      this.alertService.show('Die Passwörter stimmen nicht überein.');
      this.passwordError = true;
      this.confirmPasswordError = true;
      this.loader.visibility = false;
      return;
    }

    if (this.passwordError || this.confirmPasswordError || this.securityCodeError) {
      this.alertService.show('Es sind Validierungsfehler aufgetreten, bitte überprüfe deine Eingaben.');
      this.loader.visibility = false;
      return;
    }

    try {
      await this.authService.passwordReset(this.email, +this.securityCode, this.password);
      this.selectedLoginState = LoginState.LOGIN;
    } catch (e) {
      this.alertService.show('Es ist ein Fehler beim Zurücksetzen des Passworts aufgetreten, bitte überprüfe deine Eingaben.');
      this.passwordError = true;
      this.confirmPasswordError = true;
      this.securityCodeError = true;
    }

    this.loader.visibility = false;

  }

  async requestLoginWithMail() {
    try {
      this.loader.visibility = true;
      await this.authService.requestLoginWithMail(this.email);
      this.selectedLoginState = LoginState.KNOWN_USER;
      this.alertService.show('Wir haben dir eine E-Mail mit einem Einmallink zum Login zugesendet.', 1000 * 10, AlertType.Success);
      localStorage.setItem('last_used_email', this.email.toLowerCase());
    } catch (e) {
      this.alertService.show('Es ist ein Fehler beim Anfordern des Logins aufgetreten, bitte überprüfe deine Eingaben.');
      this.emailError = true;
    } finally {
      this.loader.visibility = false;
    }
  }

  async loginWithMail(secret: string) {

    this.loader.visibility = true;

    try {
      const session = await this.authService.confirmLoginWithMail(secret, true);
      localStorage.setItem('sessionID', session.ec ? this.crypto.decryptSingleValue(session.secret) : session.secret);
      localStorage.setItem('last_used_email', this.email.toLowerCase());
      this.email = '';
      await this.sendPublicKey();
      this.afterLogin().then();
    } catch (e) {
      this.alertService.show('Der Link ist ungültig oder abgelaufen.');
      this.emailError = true;
      this.passwordError = true;
    } finally {
      this.password = '';
      this.loader.visibility = false;
    }

  }

}

export enum LoginState {
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  KNOWN_USER,
  PASSWORD
}
