import {Injectable} from "@angular/core";
import {BaseService, HttpMethod} from "./base.service";
import {MapType} from "../classes/MapType";
import {MapKitCredentials} from "../types/mapkit.types";

@Injectable()
export class MapKitService {

  constructor(private base: BaseService) {
    //
  }

  async getDetails() {
    return await this.base.executeRequest<MapKitCredentials>('/map/details', HttpMethod.GET);
  }

  async getToken() {
    return await this.base.executeRequest<{ token: string }>('/mapkit/token', HttpMethod.GET);
  }

}
