import {Injectable} from "@angular/core";
import {UserDetails} from "../models/UserDetail";
import {BaseService, HttpMethod} from "./base.service";
import {ErrorReport} from "../models/ErrorReport";

@Injectable()
export class AdminService {

  constructor(private base: BaseService) {
    //
  }

  async getUsers() {
    return await this.base.executeRequest<UserDetails[]>('/manage/users', HttpMethod.GET);
  }

  async runJob(path: string) {
    return await this.base.executeRequest<void>(`/job/${path}`, HttpMethod.POST);
  }

  async getErrors() {
    return await this.base.executeRequest<ErrorReport[]>('/manage/errors', HttpMethod.GET);
  }

  async saveUser(user: UserDetails) {
    return await this.base.executeRequest<boolean>('/manage/users', HttpMethod.PUT, user);
  }

  async loginAs(user: UserDetails) {
    return await this.base.executeRequest<void>('/user/login', HttpMethod.POST, {
      id: user.user.id
    });
  }

}
