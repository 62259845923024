<div *ngIf="services.issue.visible" class="popUp">
  <div (click)="services.issue.visible = false" class="header">
    <div>
      Feedback / Bug - Report
    </div>
    <img src="assets/cancel.svg">
  </div>
  <div class="form">
    <span class="p-float-label p-fluid">
        <input pInputText id="title" [(ngModel)]="title"/>
        <label for="title">Zusammenfassung</label>
    </span>
    <span class="p-float-label p-fluid">
      <textarea [(ngModel)]="description" id="float-input" rows="6" pInputTextarea></textarea>
      <label for="float-input">Beschreibung</label>
    </span>
    <span class="p-float-label">
      <p-button (click)="save()" label="Absenden"></p-button>
    </span>
  </div>
</div>
