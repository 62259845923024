import {Injectable} from "@angular/core";
import {BaseService, HttpMethod} from "./base.service";

@Injectable()
export class IssueService {

  visible = false;

  constructor(private base: BaseService) {
    //
  }

  async postIssue(title: string, description: string) {
    return await this.base.executeRequest<void>('/issue', HttpMethod.POST, {
      title,
      description
    });
  }

}
