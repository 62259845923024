import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpEvent, HttpEventType} from "@angular/common/http";
import {Crew} from "../models/Crew";
import {catchError, EMPTY, Subject} from "rxjs";
import {CrewMember} from "../models/CrewMember";
import {Journal} from "../models/Journal";
import {BaseService, HttpMethod} from "./base.service";
import {AssetType} from "../classes/AssetType";
import {JournalStats} from "../classes/JournalStats";
import {JournalEntityAsset} from "../models/JournalEntityAsset";

@Injectable()
export class CrewService {

  constructor(private base: BaseService) {
    //
  }

  async getCrews() {
    return await this.base.executeRequest<Crew[]>('/crews', HttpMethod.GET);
  }

  async getCrew(id: number) {
    return await this.base.executeRequest<Crew>(`/crew/${id}`, HttpMethod.GET);
  }

  async deleteCrew(id: number) {
    return await this.base.executeRequest<void>(`/crew/${id}`, HttpMethod.DELETE);
  }

  async deleteAssignmentOfCrewMember(crewId: number, crewMemberId: number, type: string) {
    return await this.base.executeRequest<void>(`/crew/${crewId}/${crewMemberId}?type=${type}`, HttpMethod.DELETE);
  }

  async patchCrew(id: number, crew: Crew) {
    return await this.base.executeRequest<void>(`/crew/${id}`, HttpMethod.PATCH, crew);
  }

  async postCrew(crew: Crew) {
    return await this.base.executeRequest<Crew>('/crew', HttpMethod.POST, crew);
  }

  async getCrewMembers() {
    return await this.base.executeRequest<CrewMember[]>('/crew/members', HttpMethod.GET, {}, false, false, undefined, false);
  }

  async getCrewMember(id: number) {
    return await this.base.executeRequest<CrewMember>(`/crew/member/${id}`, HttpMethod.GET);
  }

  async deleteCrewMember(id: number) {
    return await this.base.executeRequest<void>(`/crew/member/${id}`, HttpMethod.DELETE);
  }

  async patchCrewMember(id: number, crewMember: CrewMember) {
    return await this.base.executeRequest<void>(`/crew/member/${id}`, HttpMethod.PATCH, crewMember);
  }

  async postCrewMember(crewMember: CrewMember) {
    return await this.base.executeRequest<CrewMember>('/crew/member', HttpMethod.POST, crewMember);
  }

}
