import {Component, OnInit} from '@angular/core';
import {VideoPlayerService} from "./video-player.service";
import {NgIf} from "@angular/common";
import {SecurityQueryService} from "../securityQuery/securityQuery.service";
import {RegistryService} from "../../services/registry.service";
import {SecurityQueryResult} from "../securityQuery/securityQuery.component";
import {LoaderService} from "../loader/loader.service";
import {del} from "idb-keyval";
import {Observable} from "rxjs";
import {SafeUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-video-player',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './video-player.component.html',
  styleUrl: './video-player.component.scss'
})
export class VideoPlayerComponent implements OnInit {

  showCloseButton = false;

  constructor(public service: VideoPlayerService,
              private securityQuery: SecurityQueryService,
              private services: RegistryService,
              private loader: LoaderService) {
    //
  }

  ngOnInit() {
    /* this.service.videoSelected.subscribe(() => {
      console.log('video selected');
      this.services.journal.getVideo(this.service.selectedVideo!.id, 'bytes=0-').subscribe(result => {
        const videoBlob = new Blob([result], { type: 'video/mp4' });
        this.videoUrl = window.URL.createObjectURL(videoBlob);
      });
    }); */
  }

  async deleteVideo() {
    if (await this.securityQuery.show('Möchtest du das Video wirklich löschen?', true, true, false) == SecurityQueryResult.Yes) {
      this.loader.visibility = true;

      try {
        await this.services.journal.deleteAsset(this.service.selectedVideo!.id);
        this.service.selectedVideo = undefined;
      } catch (e) {
        //
      }

      this.loader.visibility = false;

    }
  }

  protected readonly del = del;
}
