import {Injectable} from "@angular/core";
import {BaseService, HttpMethod} from "./base.service";
import {Gateway} from "../models/Gateway";

@Injectable()
export class GatewayService {

  constructor(private base: BaseService) {
    //
  }

  async getGateways() {
    return await this.base.executeRequest<Gateway[]>('/gateways', HttpMethod.GET);
  }

  async saveGateway(gateway: Gateway) {
    return await this.base.executeRequest<void>('/gateway', HttpMethod.PUT, gateway);
  }

}
