import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {JournalPreviewMapService} from "./journal-preview-map.service";
import {NgIf} from "@angular/common";
import {Router, RouterLink} from "@angular/router";

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [
    NgIf,
    RouterLink
  ],
  providers: [],
  templateUrl: './journal-preview-map.component.html',
  styleUrl: './journal-preview-map.component.scss'
})
export class JournalPreviewMapComponent {

  @Input() readonly = true;
  @Input() journalId?: number;

  @Output() onExit = new EventEmitter<void>();

  constructor(public service: JournalPreviewMapService, private router: Router) {
    //
  }

  async exit() {
    await this.router.navigate(['/journal/' + this.journalId + '/1']);
    this.onExit.emit();
  }

}
