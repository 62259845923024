<div class="editor-container">
  <app-journal-entity-metadata [journal]="journal"
                               [editMode]="editMode"
                               (editModeChanged)="editMode = $event"
                               (save)="save()"
                               (shareButtonClicked)="shareModalVisibility = true"
                               (appButtonClicked)="showAppOverlay()"/>
  <app-journal-crew *ngIf="journal !== undefined"
                    [journal]="journal" [crew]="journal.crew"
                    (selectedCrew)="crewSelected($event)"
                    [readonly]="true"/>
  <!-- <app-journal-actions *ngIf="!journal?.readonly" (openConnectAppModal)="showAppOverlay()"
                       (openShareModal)="shareModalVisibility = true"
                       (openPreview)="previewModalVisibility = true"/> -->
  <app-journal-entities *ngIf="journal != null"
                        [journal]="journal"
                        [editMode]="editMode"
                        (editModeChange)="editMode = $event"
                        (trackSelected)="selectedTrack = $event"
                        (save)="save()"
                        [deleteJournalEntities]="deleteJournalEntities"
                        (entitySelected)="selectedEntity = $event"
                        (thumbnailUploaded)="refreshAssets($event)"
                        (previewOpened)="selectedPreviewEntity = $event"
                        style="margin-top: 2.5rem;"/>
  <div *ngIf="journal !== undefined && !journal.readonly" class="options">
    <div (click)="advancedOptions = !advancedOptions" class="option-toggle">
      weitere Optionen <span [innerText]="advancedOptions ? 'ausblenden' : 'einblenden'"></span>
    </div>
    <div *ngIf="advancedOptions" class="danger-area">
      <label>Gefahrenszone</label>
      <div class="card">
        <label>
          Foto-Verarbeitung
        </label>
        <span>
          Durch diese Aktion wird eine Verarbeitung der Fotos angestoßen, dies ist nützlich, um die Ladedauer zu verkürzen.
        </span>
        <p-button label="Ausführen" [outlined]="true" (click)="processImages()" severity="danger"
                  icon="pi pi-exclamation-circle" size="small"/>
      </div>
      <div class="card">
        <label>
          Fotos & Videos löschen
        </label>
        <span>
          Durch diese Aktion werden alle <b>Fotos und Videos</b> gelöscht zu diesem Törn gelöscht, diese Aktion kann nicht rückgängig gemacht werden.
        </span>
        <p-button label="Löschung der Fotos und Videos ausführen" [outlined]="true" (click)="deleteAssets(undefined)"
                  severity="danger" icon="pi pi-exclamation-circle" size="small"/>
      </div>
      <div class="card">
        <label>
          Fotos löschen
        </label>
        <span>
          Durch diese Aktion werden alle <b>Fotos</b> zu diesem Törn gelöscht, diese Aktion kann nicht rückgängig gemacht werden.
        </span>
        <p-button label="Löschung der Fotos ausführen" [outlined]="true" (click)="deleteAssets(AssetType.Image)"
                  severity="danger" icon="pi pi-exclamation-circle" size="small"/>
      </div>
      <div class="card">
        <label>
          Videos löschen
        </label>
        <span>
          Durch diese Aktion werden alle <b>Videos</b> zu diesem Törn gelöscht, diese Aktion kann nicht rückgängig gemacht werden.
        </span>
        <p-button label="Löschung der Videos
          ausführen" [outlined]="true" (click)="deleteAssets(AssetType.Video)" severity="danger"
                  icon="pi pi-exclamation-circle" size="small"/>
      </div>
      <div class="card">
        <label>Löschen dieses Törns</label>
        <span>
          Durch das Löschen dieses Törns werden alle Daten unwiderruflich gelöscht. Dieser Vorgang kann nicht rückgängig gemacht werden.
        </span>
        <p-button label="Unwiederruflich Löschen" [outlined]="true" (click)="delete()" severity="danger"
                  icon="pi pi-exclamation-circle" size="small"/>
      </div>
    </div>
  </div>
</div>

<app-picture-preview (close)="closePreview()"/>

<app-journal-edit-modal *ngIf="selectedEntity != undefined" [obj]="selectedEntity"
                        [journalId]="journal!.id" (updated)="closeEditor($event)"/>

<app-app-connect *ngIf="journal !== undefined"
                 [qrCodeData]="qrCodeData" [showAppSecret]="showAppSecret" [readOnly]="journal!.readonly"
                 (close)="showAppSecret = false"/>

<app-journal-share *ngIf="shareModalVisibility" [journal]="journal" (close)="shareModalVisibility = false;"/>

<app-journal-preview *ngIf="previewModalVisibility" [journal]="journal" (close)="previewModalVisibility = false"/>

<app-track-overlay [selectedTrack]="selectedTrack"/>

