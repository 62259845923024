import {ApplicationConfig, ChangeDetectorRef} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import { provideHttpClient } from "@angular/common/http";
import {AppService} from "./app.service";
import {SortService} from "./sort/sort.service";
import {AlertModalService} from "./alert-modal/alert-modal.service";
import {LoaderService} from "./loader/loader.service";
import {SecurityQueryService} from "./securityQuery/securityQuery.service";
import {BaseService} from "../services/base.service";
import {RegistryService} from "../services/registry.service";
import {JournalService} from "../services/journal.service";
import {UserDetailsService} from "../services/userDetails.service";
import {IssueService} from "../services/issue.service";
import {PicturePreviewService} from "./picture-preview/picture-preview.service";
import {CrewEditorService} from "./crew-editor/crew-editor.service";
import {StatsService} from "../services/stats.service";
import {AdminService} from "../services/admin.service";
import {GatewayService} from "../services/gateway.service";
import {GatewayManagementService} from "./admin/gateway-management/gateway-management.service";
import {TracksService} from "../services/tracks.service";
import {ExtractDataService} from "../services/extractData.service";
import {VideoPlayerService} from "./video-player/video-player.service";
import {InfoService} from "../services/info.service";
import {MapService} from "./map/map.service";
import {MembershipService} from "../services/membership.service";
import {MapKitService} from "../services/mapkit.service";
import {SocketService} from "../services/socket.service";
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {ExternalService} from "../services/external.service";
import {PaymentService} from "../services/payment.service";
import {UserService} from "../services/user.Service";
import {AuthService} from "../services/auth.Service";
import {CryptoService} from "../services/crypto.Service";
import {PermissionService} from "../services/permission.Service";
import {WebAuthnService} from "../services/webAuthn.service";
import {HostService} from "../services/host.Service";
import {VerifySessionService} from "./verfiy-session/verify-session.service";
import {SettingsService} from "../services/settings.service";
import {JournalPreviewMapService} from "./journal-preview/journal-preview-map/journal-preview-map.service";
import {CrewService} from "../services/crew.service";
import {CmsService} from "../services/cms.service";

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(),
    provideRouter(routes),
    provideAnimationsAsync(),
    AppService,
    SortService,
    AlertModalService,
    LoaderService,
    SecurityQueryService,
    BaseService,
    RegistryService,
    JournalService,
    UserService,
    UserDetailsService,
    IssueService,
    PicturePreviewService,
    CrewEditorService,
    StatsService,
    AdminService,
    GatewayService,
    GatewayManagementService,
    TracksService,
    ExtractDataService,
    VideoPlayerService,
    InfoService,
    MapService,
    MembershipService,
    MapKitService,
    SocketService,
    ExternalService,
    PaymentService,
    AuthService,
    CryptoService,
    PermissionService,
    UserService,
    WebAuthnService,
    HostService,
    VerifySessionService,
    SettingsService,
    JournalPreviewMapService,
    CrewService,
    CmsService
  ]
};

export const LogPrefix = {
  I: 'VDR_I',
  E: 'VDR_E',
  W: 'VDR_W',
  D: 'VDR_D'
};
