import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {AlertModalService} from "../app/alert-modal/alert-modal.service";
import {environment} from "../environments/environment";
import {catchError, EMPTY} from "rxjs";
import {LogPrefix} from "../app/app.config";
import {HostService} from "./host.Service";

@Injectable()
export class BaseService {

  selectedGateway: number = -1;
  progress = -1;

  get url() {
    return this.hostService.buildUrl();
  }

  get masterServer() {
    return this.hostService.buildUrl(true, environment.backend.master);
  }

  get httpOptions() {
    return {
      headers: {
        Authorization: this.getAuthorizationToken(),
        'X-Gateway-Identifier': this.selectedGateway > 0 ? this.selectedGateway.toString() : '',
        'X-Socket-Id': sessionStorage.getItem('Socket-ID') || '',
      }
    };
  }

  constructor(private alertService: AlertModalService,
              private hostService: HostService,
              protected http: HttpClient) {
    //
  }

  executeRequest<T>(
    path: string,
    method: HttpMethod,
    body: any = {},
    onMaster: boolean = false,
    extendedOptions: boolean = false,
    responseType?: string,
    handleError: boolean = true): Promise<T> {
    const url = path.startsWith('http') ? path : `${onMaster ? this.masterServer : this.url}${path}`;
    return new Promise<T>((resolve, reject) => {
      this.executeRequestGetObserver<T>(url, method, body, reject, !path.startsWith('http'), extendedOptions, responseType, handleError)
        .subscribe(result => {
          resolve(result);
        });
    });
  }

  executeRequestGetObserver<T>(
    url: string,
    method: HttpMethod,
    body: any = {},
    reject: () => void,
    withOptions: boolean = true,
    withExtendedOptions: boolean = false,
    responseType?: string,
    handleError: boolean = true) {
    return this.getBaseRequest<T>(url, method, body, withOptions, withExtendedOptions, responseType)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.handleError(error, !handleError);
          reject();
          return EMPTY;
        })
      )
  }

  private getBaseRequest<T>(url: string, httpMethod: HttpMethod, body: any = {}, withOptions: boolean = true, withExtendedOptions: boolean, responseType?: string) {
    // console.debug(`Request: ${url}, Method: ${HttpMethod[httpMethod]}, withOptions: ${withOptions}, withExtendedOptions: ${withExtendedOptions}`);
    const extendedOptions = {
      ...this.httpOptions,
      reportProgress: true,
      observe: 'events'
    };
    const options = withExtendedOptions ? extendedOptions : withOptions ? {
      ...this.httpOptions,
      responseType
    } : {};

    switch (httpMethod) {
      case HttpMethod.GET:
        return this.http.get<T>(url, options);
      case HttpMethod.POST:
        return this.http.post<T>(url, body, options);
      case HttpMethod.PUT:
        return this.http.put<T>(url, body, options);
      case HttpMethod.PATCH:
        return this.http.patch<T>(url, body, options);
      case HttpMethod.DELETE:
        return this.http.delete<T>(url, options);
    }
  }

  handleError(error: HttpErrorResponse, ignore: boolean = false) {
    if (error?.status === 429 && !ignore) {
      this.alertService.show('Houston, wir haben ein Problem! Bitte versuchen Sie es später erneut.');
    }
    if (error?.status === 401 && !ignore) {
      console.log(error);
      this.alertService.show('Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.');
    } else if (!(error.error instanceof Error)) {
      if (error.error?.description_de != null && !ignore) {
        this.alertService.show(error.error?.description_de);
        console.error(error.error?.description_de);
      } else if (error.error?.errors != null && !ignore) {
        let message = '';
        error.error?.errors.forEach((x: { description: string }) => {
          if (message != '') {
            message += '<br>';
          }
          message += x.description;
        })
        this.alertService.show(message);
        console.error(message);
      } else if (error.url != null && error.status != 0) {
        const message = `Fehler beim Aufruf von ${error.url} (${error.status}).`;
        console.error(`${LogPrefix.E} ${message}`);
      }
    }
  }

  getAuthorizationToken() {
    let token = localStorage.getItem('sessionID');
    if (token == null) {
      token = sessionStorage.getItem('sessionID');
      if (token == null) {
        token = '';
      }
    }
    return token;
  }

}

export enum HttpMethod {
  GET,
  POST,
  PUT,
  PATCH,
  DELETE
}
