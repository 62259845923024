<div class="container">
  <section *ngIf="isAdmin">
    <div class="profile-title">
      System-Parameter (Konfiguration)
    </div>
    <p-message *ngIf="!isVerified" severity="info"
               text="Um den Inhalt dieser Seite zu sehen ist aus Sicherheitsgründen eine zusätzliche Identifierung deiner Sitzung notwendig."></p-message>
    <p-button *ngIf="!isVerified" (click)="verifySessionOnClick()" label="Verifizieren" severity="secondary"></p-button>
    <div *ngIf="isVerified" class="items">
      <div *ngFor="let item of setupItems" class="item">
        <div class="key" [innerText]="item.name"></div>
        <div *ngIf="getType(item) == 'boolean'" class="boolean value">
          <p-toggleButton class="p-fluid" [(ngModel)]="item.value" onLabel="Aktiviert" (ngModelChange)="saveItem(item)"
                          offLabel="Deaktiviert" [style]="{'font-size': '0.8rem'}"></p-toggleButton>
        </div>
        <div *ngIf="getType(item) == 'string'" class="string value p-fluid">
          <input pInputText class="ng-invalid p-inputtext-sm p-fluid" [(ngModel)]="item.value"
                 (focusout)="saveItem(item)"/>
        </div>
        <div *ngIf="getType(item) == 'number'" class="number value p-fluid">
          <p-inputNumber inputId="integeronly" class="p-inputtext-sm p-fluid" [(ngModel)]="item.value"
                         (focusout)="saveItem(item)" [useGrouping]="false"></p-inputNumber>
        </div>
      </div>
    </div>
  </section>
</div>
