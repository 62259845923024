import {Injectable} from "@angular/core";
import {BaseService, HttpMethod} from "./base.service";

@Injectable()
export class StatsService {

  constructor(private base: BaseService) {
    //
  }

  async getStats() {
    return await this.base.executeRequest<{
      miles: number,
      journals: number,
      tracks: number
    }>(`/stats`, HttpMethod.GET, {}, false, false, undefined, false);
  }

}
