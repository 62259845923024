<div class="wrapper">
  <div class="stats">
    <div class="stats-item">
      <label>Benutzer</label>
      <div>
        <img src="assets/profile-user.svg">
        <span>0</span>
      </div>
    </div>
    <div class="stats-item">
      <label>Mitgliedschaften</label>
      <div>
        <img src="assets/premium-quality.svg">
        <span>0</span>
      </div>
    </div>
    <div class="stats-item">
      <label>Logs</label>
      <div>
        <img src="assets/task-list.svg">
        <span>0</span>
      </div>
    </div>
    <div class="stats-item">
      <label>Törns</label>
      <div>
        <img src="assets/pen_white.svg">
        <span>0</span>
      </div>
    </div>
    <div class="stats-item">
      <label>Anzahl an Fotos</label>
      <div>
        <img src="assets/image-gallery.svg">
        <span>0</span>
      </div>
    </div>
    <div class="stats-item">
      <label>Anzahl an Videos</label>
      <div>
        <img src="assets/video-camera_white.svg">
        <span>0</span>
      </div>
    </div>
    <div class="stats-item">
      <label>Speicherverbrauch</label>
      <div>
        <img src="assets/database-table.svg">
        <span>0</span>
      </div>
    </div>
    <div class="stats-item">
      <label>Traffic (Heute)</label>
      <div>
        <img src="assets/mobile-data.svg">
        <span>0</span>
      </div>
    </div>
    <div class="stats-item">
      <label>VDR (BlackBox)</label>
      <div>
        <img src="assets/chip.svg">
        <span>0</span>
      </div>
    </div>
  </div>
</div>
