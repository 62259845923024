import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {Journal} from "../../../models/Journal";
import {BaseService} from "../../../services/base.service";
import {NgIf} from "@angular/common";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {JournalPreviewCoreComponent} from "../../journal-preview/journal-preview-core/journal-preview-core.component";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-journal-preview',
  standalone: true,
  imports: [
    NgIf,
    JournalPreviewCoreComponent
  ],
  templateUrl: './journal-preview.component.html',
  styleUrl: './journal-preview.component.scss'
})
export class JournalPreviewComponent implements OnDestroy {

  @Input() journal?: Journal;
  @Output() close = new EventEmitter<void>();

  private sub: any;

  journalLink?: string;
  journalInit = false;

  constructor(private route: ActivatedRoute) {
    if (this.journal === undefined) {
      this.sub = this.route.params.subscribe(params => {
        this.journalLink = params['link'];
        this.journalInit = true;

        history.pushState(null, '', window.location.href);

        window.onpopstate = () => {
          history.pushState(null, '', window.location.href);
          this.close.emit();
        };
      });
    } else {
      this.journalInit = true;
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
