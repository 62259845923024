import {Routes} from '@angular/router';
import {TracksComponent} from "./tracks/tracks.component";
import {LoginComponent} from "./login/login.component";
import {JournalComponent} from "./journal/journal.component";
import {AccountComponent} from "./account/account.component";
import {UserManagementComponent} from "./admin/user-management/user-management.component";
import {ErrorManagementComponent} from "./admin/error-management/error-management.component";
import {TrackComponent} from "./track/track.component";
import {hasUnsavedChangesGuard} from "./guards/unsave-changes.guard";
import {JournalEditorComponent} from "./journal/journal-editor/journal-editor.component";
import {authGuard} from "./guards/auth.guard";
import {MembershipManagementComponent} from "./admin/membership-management/membership-management.component";
import {AdminOverviewComponent} from "./admin/admin-overview/admin-overview.component";
import {NewJournalOverviewComponent} from "./new-journal-overview/new-journal-overview.component";
import {JournalPreviewComponent} from "./journal/journal-preview/journal-preview.component";
import {CrewsComponent} from "./crews/crews.component";
import {ContactsComponent} from "./contacts/contacts.component";

export const routes: Routes = [
  {path: 'tracks', component: TracksComponent, canDeactivate: [hasUnsavedChangesGuard], canActivate: [authGuard]},
  {
    path: 'track/:gateway/:track',
    component: TrackComponent,
    canDeactivate: [hasUnsavedChangesGuard],
    canActivate: [authGuard]
  },
  {
    path: 'journal',
    component: NewJournalOverviewComponent,
    canDeactivate: [hasUnsavedChangesGuard],
    canActivate: [authGuard]
  },
  {
    path: 'journal/:id',
    component: JournalEditorComponent,
    canDeactivate: [hasUnsavedChangesGuard],
    canActivate: [authGuard]
  },
  {
    path: 'journal/:id/:edit',
    component: JournalEditorComponent,
    canDeactivate: [hasUnsavedChangesGuard],
    canActivate: [authGuard]
  },
  {
    path: 'crews',
    component: CrewsComponent,
    canDeactivate: [hasUnsavedChangesGuard],
    canActivate: [authGuard]
  },
  {
    path: 'contacts',
    component: ContactsComponent,
    canDeactivate: [hasUnsavedChangesGuard],
    canActivate: [authGuard]
  },
  {path: 'login', component: LoginComponent},
  {path: 'login/:secret', component: LoginComponent},
  {path: 'settings', component: AccountComponent, canActivate: [authGuard]},
  {path: 'settings/admin', component: AdminOverviewComponent, canActivate: [authGuard]},
  {path: 'settings/admin/users', component: UserManagementComponent, canActivate: [authGuard]},
  {path: 'settings/admin/errors', component: ErrorManagementComponent, canActivate: [authGuard]},
  {path: 'settings/admin/memberships', component: MembershipManagementComponent, canActivate: [authGuard]},
  {path: ':link', component: JournalPreviewComponent},
  {path: '**', redirectTo: '/journal'}
];
