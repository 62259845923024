import {Component, Input, Output} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {Subject} from "rxjs";

@Component({
  selector: 'app-toggle-button',
  standalone: true,
  imports: [
    FormsModule
  ],
  templateUrl: './toggle-button.component.html',
  styleUrl: './toggle-button.component.scss'
})
export class ToggleButtonComponent {
  @Input() label: string = '';
  @Input() value: boolean = false;
  @Output() valueChanged = new Subject<void>();
}
