<div *ngIf="entity != null" class="preview-images-wrapper">
  <div *ngIf="entity.galleryImages" class="entity-preview-images">
    <div *ngFor="let image of entity.galleryImages; let index = index"
         class="entity-preview-image" key="index"
         [ngClass]="entity.galleryImages.length <= 2 ? 'full-width': ''"
         (contextmenu)="onContextMenu(image, $event)"
         (click)="imageClick(index, entity)">
      <img [src]="image.thumbnailImageSrc"
           [alt]="image.alt"
           style="cursor: pointer"/>
      <div class="play-overlay">
        <img *ngIf="isVideo(image.asset)" src="assets/play.svg"/>
      </div>
    </div>
    <p-contextMenu #cm [model]="imageContextMenuItems" (onHide)="onHide()"
                   [style]="{top: '0', left: '0'}"/>
  </div>
</div>

<div class="display-more-wrapper" *ngIf="!entity!.displayMore && entity!.assets.length > 4">
  <div class="display-more" (click)="loadMore(entity!)">
    <div class="icon">
      <i class="pi pi-arrow-circle-down"></i>
    </div>
    <span>mehr Bilder anzeigen</span>
  </div>
</div>

<p-galleria (keydown)="imageKeyDown($event)"
            [value]="entity!.galleryImages"
            [(visible)]="entity!.fullScreen"
            [(activeIndex)]="entity!.selectedAssetIndex"
            [responsiveOptions]="responsiveOptions"
            [containerStyle]="{ 'max-width': '850px' }"
            [numVisible]="7"
            [circular]="true"
            [fullScreen]="true"
            [showItemNavigators]="true"
            [showThumbnails]="false">
  <ng-template pTemplate="item" let-item>
    <img *ngIf="isImage(item.asset)"
         [src]="item.itemImageSrc"
         style="width: 100%; display: block;" (load)="imageLoaded(item)"/>
    <video controls *ngIf="isVideo(item.asset)"
           style="height: auto; width: 100%;"
           id="player_{{entity!.selectedAsset?.id}}">
      <source [attr.src]="item.itemImageSrc"
              [type]="getVideoMimeType(item.asset)">
    </video>
  </ng-template>
</p-galleria>
