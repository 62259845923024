export enum SignalKIdentifier {
  electricalAlternators0RevolutionsValue = 'electrical.alternators.0.revolutions',
  electricalBatteriesMainLifetimeDischargeValue = 'electrical.batteries.main.lifetimeDischarge',
  electricalBatteriesMainVoltageValue = 'electrical.batteries.main.voltage',
  electricalBatteriesMainCurrentValue = 'electrical.batteries.main.current',
  electricalBatteriesMainCapacityConsumedChargeValue = 'electrical.batteries.main.capacity.consumedCharge',
  electricalBatteriesMainCapacityStateOfChargeValue = 'electrical.batteries.main.capacity.stateOfCharge',
  electricalBatteriesMainCapacityTimeRemainingValue = 'electrical.batteries.main.capacity.timeRemaining',
  navigationPositionMetaPropertiesLongitudeExample = 'navigation.position.meta.properties.longitude.example',
  navigationPositionMetaPropertiesLatitudeExample = 'navigation.position.meta.properties.latitude.example',
  navigationPositionValueLongitude = 'navigation.position.longitude',
  navigationPositionValueLatitude = 'navigation.position.latitude',
  navigationGnssSatellitesValue = 'navigation.gnss.satellites',
  navigationGnssAntennaAltitudeValue = 'navigation.gnss.antennaAltitude',
  navigationGnssHorizontalDilutionValue = 'navigation.gnss.horizontalDilution',
  navigationGnssDifferentialAgeValue = 'navigation.gnss.differentialAge',
  navigationGnssDifferentialReferenceValue = 'navigation.gnss.differentialReference',
  navigationGnssSatellitesInViewValueCount = 'navigation.gnss.satellitesInView.value.count',
  navigationGnssSatellitesInViewValueSatellites0Id = 'navigation.gnss.satellitesInView.value.satellites.0.id',
  navigationGnssSatellitesInViewValueSatellites0Elevation = 'navigation.gnss.satellitesInView.value.satellites.0.elevation',
  navigationGnssSatellitesInViewValueSatellites0Azimuth = 'navigation.gnss.satellitesInView.value.satellites.0.azimuth',
  navigationGnssSatellitesInViewValueSatellites0SNR = 'navigation.gnss.satellitesInView.value.satellites.0.SNR',
  navigationSpeedOverGround = 'navigation.speedOverGround',
  navigationCourseOverGroundTrue = 'navigation.courseOverGroundTrue',
  navigationCourseOverGroundMagnetic = 'navigation.courseOverGroundMagnetic',
  environmentOutsideTemperature = 'environment.outside.temperature',
  environmentWindAngleApparent = 'environment.wind.angleApparent',
  environmentWindSpeedApparent = 'environment.wind.speedApparent',
}
