import {NgModule} from "@angular/core";
import {
  AuthenticationResponseJSON,
  PublicKeyCredentialCreationOptionsJSON,
  PublicKeyCredentialRequestOptionsJSON,
  RegistrationResponseJSON
} from "@simplewebauthn/typescript-types";
import {startAuthentication, startRegistration} from "@simplewebauthn/browser";
import {Session} from "../models/Session";
import {BaseService, HttpMethod} from "./base.service";
import {WebAuthnRegistration} from "../models/WebAuthnRegistration";
import {CryptoService} from "./crypto.Service";

@NgModule()
export class WebAuthnService {

  constructor(private base: BaseService, private cryptoService: CryptoService) {
    //
  }

  getChallenge() {
    return this.base.executeRequest<PublicKeyCredentialCreationOptionsJSON>('/fido2/challenge', HttpMethod.GET);
  }

  getAuthChallenge(email: string) {
    return this.base.executeRequest<PublicKeyCredentialRequestOptionsJSON>('/auth/fido2/auth/challenge/' + email, HttpMethod.GET);
  }

  verifyRegister(regResp: RegistrationResponseJSON) {
    return this.base.executeRequest<void>('/fido2/verify/register', HttpMethod.POST, regResp);
  }

  verifyAuth(regResp: AuthenticationResponseJSON) {
    return this.base.executeRequest<Session>('/auth/fido2/auth', HttpMethod.POST, {
      ...regResp,
      pubKey: this.cryptoService.publicKey
    });
  }

  async registerClient() {
    const challenge = await this.getChallenge();

    if (challenge == null) {
      throw new Error('Registration of Client failed!');
    }

    const attResp = await startRegistration(challenge);
    await this.verifyRegister(attResp);
  }

  async getRegistrations() {
    return this.base.executeRequest<WebAuthnRegistration[]>('/fido2/registrations', HttpMethod.GET);
  }

  async updateRegistration(key: WebAuthnRegistration) {
    return this.base.executeRequest<void>('/fido2/registrations/' + key.id, HttpMethod.PATCH, key);
  }

  async deleteRegistration(key: WebAuthnRegistration) {
    return this.base.executeRequest<void>('/fido2/registrations/' + key.id, HttpMethod.DELETE);
  }

  async authClient(email: string) {
    const challenge = await this.getAuthChallenge(email);

    if (challenge == null) {
      return;
    }

    try {
      const authQuery = await startAuthentication(challenge, false);
      const session = await this.verifyAuth(authQuery);

      if (session == null) {
        return false;
      }

      localStorage.setItem('sessionID', session.secret);
      return true;

    } catch (error) {
      console.log(error);
      return false;
    }

  }

}
