<div class="wrapper">
  <div class="container">
    <div class="head">
      <label>Teilen</label>
      <i (click)="close.emit()" class="pi pi-times-circle"></i>
    </div>
    <span>
      Teile den Törn-Bericht mit Familie und Freunden, indem du dir einen Link generierst, über der dieser Törn aufgerufen werden kann.<br>
      Du kannst hier dir einen einzigartigen Link selbst erstellen oder generieren lassen.<br>
      Sofern du deinen Törn bereits freigegeben hast, kannst du auch die Freigabe wieder zurückziehen und den erstellen Link somit wieder ungültig machen.
    </span>
    <p-inputGroup>
      <p-inputGroupAddon>https://törn.link/</p-inputGroupAddon>
      <input [(ngModel)]="permalink" type="text" pInputText placeholder="Permalink" style="min-width: 128px"/>
      <button (click)="shareLink()" *ngIf="journal?.permalink != null" type="button" pButton icon="pi pi-share-alt"
              class="p-button-info m-hide"
              pTooltip="Teilen" tooltipPosition="bottom"></button>
      <button (click)="createMailTemplate()" *ngIf="journal?.permalink != null" type="button" pButton icon="pi pi-at"
              class="p-button-info m-hide"
              pTooltip="Teilen per E-Mail" tooltipPosition="bottom"></button>
      <button (click)="generatePermalink()" type="button" pButton icon="pi pi-refresh" class="p-button-secondary m-hide"
              pTooltip="Neuen Link generieren" tooltipPosition="bottom"></button>
      <button (click)="deletePermalink()" *ngIf="journal?.permalink != null" type="button" pButton icon="pi pi-trash"
              class="p-button-danger m-hide"
              pTooltip="Link wiederrufen" tooltipPosition="bottom"></button>
      <button (click)="share()" class=" m-hide" style="width: 256px;" *ngIf="journal?.permalink == null" type="button"
              pButton
              label="Freigeben"></button>
    </p-inputGroup>

    <p-inputGroup class="normal-hide">
      <button (click)="shareLink()" *ngIf="journal?.permalink != null" type="button" pButton icon="pi pi-share-alt"
              class="p-button-info"
              pTooltip="Teilen" tooltipPosition="bottom"></button>
      <button (click)="createMailTemplate()" *ngIf="journal?.permalink != null" type="button" pButton icon="pi pi-at"
              class="p-button-info"
              pTooltip="Teilen per E-Mail" tooltipPosition="bottom"></button>
      <button (click)="generatePermalink()" type="button" pButton icon="pi pi-refresh" class="p-button-secondary"
              pTooltip="Neuen Link generieren" tooltipPosition="bottom"></button>
      <button (click)="deletePermalink()" *ngIf="journal?.permalink != null" type="button" pButton icon="pi pi-trash"
              class="p-button-danger"
              pTooltip="Link wiederrufen" tooltipPosition="bottom"></button>
      <button (click)="share()" style="width: 256px;" *ngIf="journal?.permalink == null" type="button" pButton
              label="Freigeben"></button>
    </p-inputGroup>

  </div>
</div>
