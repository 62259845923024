import {Component, OnInit} from '@angular/core';
import {CrewMember} from "../../models/CrewMember";
import {CrewService} from "../../services/crew.service";
import {AlertModalService} from "../alert-modal/alert-modal.service";
import {LoaderService} from "../loader/loader.service";
import {SecurityQueryService} from "../securityQuery/securityQuery.service";
import {NgForOf} from "@angular/common";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-contacts',
  standalone: true,
  imports: [
    NgForOf,
    InputTextModule,
    FormsModule
  ],
  templateUrl: './contacts.component.html',
  styleUrl: './contacts.component.scss'
})
export class ContactsComponent implements OnInit {
  contacts: CrewMember[] = [];

  constructor(private crewService: CrewService,
              private alertService: AlertModalService,
              private loader: LoaderService,
              private securityQuery: SecurityQueryService) {
    //
  }


  async ngOnInit() {
    try {
      this.loader.visibility = true;
      this.contacts = await this.crewService.getCrewMembers();
    } finally {
      this.loader.visibility = false;
    }
  }

  async deleteContact(contact: CrewMember) {
    try {
      this.loader.visibility = true;
      await this.crewService.deleteCrewMember(contact.id);
      this.contacts = await this.crewService.getCrewMembers();
    } finally {
      this.loader.visibility = false;
    }
  }

  async updateContact(contact: CrewMember) {
    try {
      this.loader.visibility = true
      if (contact.id == null) {
        await this.crewService.postCrewMember(contact);
      } else {
        await this.crewService.patchCrewMember(contact.id, contact);
      }
      this.contacts = await this.crewService.getCrewMembers();
    } finally {
      this.loader.visibility = false;
    }
  }

  async addContact() {
    this.contacts.push(new CrewMember());
  }

}
