import {Component, OnInit} from '@angular/core';
import {RegistryService} from "../../../services/registry.service";
import {Router} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {ErrorReport} from "../../../models/ErrorReport";
import moment from "moment/moment";
import {DialogModule} from "primeng/dialog";
import {ProgressBarModule} from "primeng/progressbar";
import {PermissionType} from "../../../classes/PermissionType";

@Component({
  selector: 'app-error-management',
  standalone: true,
  imports: [
    FormsModule,
    NgForOf,
    NgIf,
    NgClass,
    DialogModule,
    ProgressBarModule
  ],
  templateUrl: './error-management.component.html',
  styleUrl: './error-management.component.scss'
})
export class ErrorManagementComponent implements OnInit {

  errors?: ErrorReport[];

  constructor(private services: RegistryService, private router: Router) {
    //
  }

  async ngOnInit() {

    if (!await this.services.permission.checkReadable(PermissionType.Administrator)) {
      await this.router.navigate(['/login']);
    }

    await this.loadErrors();
  }

  async loadErrors() {
    this.errors = undefined;
    this.errors = await this.services.admin.getErrors();

    // Order by date
    this.errors.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
  }

  isEven(i: number) {
    return i % 2 === 0;
  }

  getTime(error: ErrorReport) {
    moment.locale('de');
    return moment(new Date(error.createdAt)).format('DD.MM.YYYY - HH:mm:ss');
  }

}
