import {Injectable} from '@angular/core';
import {environment} from "../environments/environment";
import {BaseService} from "./base.service";
import {HostService} from "./host.Service";
import {io, Socket} from "socket.io-client";

@Injectable()
export class SocketService {

  public state = false;
  public client!: Socket;

  constructor(private base: BaseService, private hostService: HostService) {
    this.setupSocketConnection();
  }

  public setupSocketConnection(): void {

    if (environment.backend.master !== undefined && this.hostService.protocol !== undefined) {
      this.client = io(`${this.hostService.protocol}://${environment.backend.master}`);

      this.client.on('connect', () => {
        console.log('Die Socket-Verbindung wurde hergestellt!');
        if (this.client.id !== undefined) {
          sessionStorage.setItem('Socket-ID', this.client.id);
        }
        this.client.emit('auth', this.base.getAuthorizationToken());
      });

      this.client.on('auth:ok', () => {
        console.log('Die Authentifizierung der Socket-Verbindung war erfolgreich!');
        this.state = true;
      });

      this.client.on('disconnect', () => {
        console.log('Die Socket-Verbindung wurde unterbrochen!');
        this.state = false;
      });
    }

  }

}
