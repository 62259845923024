<div *ngIf="service.visibility" (click)="service.visibility = false" class="wrapper">
  <div *ngIf="service.AlertType.Error == service.type" class="alert-error">
    <div class="alert-error-img">
      <img src="assets/warning.svg">
    </div>
    <div class="alert-error-desc" [innerHTML]="service.message"></div>
  </div>
  <div *ngIf="service.AlertType.Success == service.type" class="alert-error success">
    <div class="alert-error-img">
      <img src="assets/check-mark.svg">
    </div>
    <div class="alert-error-desc" [innerHTML]="service.message"></div>
  </div>
</div>
