import {Injectable} from "@angular/core";
import {UserDetails} from "../models/UserDetail";
import {BaseService, HttpMethod} from "./base.service";

@Injectable()
export class UserDetailsService {

  constructor(private base: BaseService) {
    //
  }

  async getUserDetails() {
    return await this.base.executeRequest<UserDetails>(`/user/details`, HttpMethod.GET);
  }

  async putUserDetails(user: UserDetails) {
    return await this.base.executeRequest<void>(`/user/details`, HttpMethod.PUT, user);
  }

  async requestPhoneVerify() {
    return await this.base.executeRequest<void>(`/user/phone/request-verification`, HttpMethod.POST);
  }

  async confirmPhoneVerify(code: string) {
    return await this.base.executeRequest<void>(`/user/phone/verify`, HttpMethod.POST, {code});
  }

  async deletePhone(code: string) {
    return await this.base.executeRequest<void>(`/user/phone/${code}`, HttpMethod.DELETE);
  }

}
