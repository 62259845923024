import {Component, OnInit} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {DialogModule} from "primeng/dialog";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {CrewMember} from "../../models/CrewMember";
import {CrewService} from "../../services/crew.service";
import {InputTextModule} from "primeng/inputtext";
import {FloatLabelModule} from "primeng/floatlabel";
import {NgForOf, NgIf} from "@angular/common";
import {AlertModalService} from "../alert-modal/alert-modal.service";
import {Crew} from "../../models/Crew";
import {MultiSelectModule} from "primeng/multiselect";
import {LoaderService} from "../loader/loader.service";
import {JournalCrewComponent} from "../journal/journal-crew/journal-crew.component";
import {SecurityQueryService} from "../securityQuery/securityQuery.service";
import {SecurityQueryResult} from "../securityQuery/securityQuery.component";
import {CrewCreatorDialogComponent} from "../crew-creator-dialog/crew-creator-dialog.component";

@Component({
  selector: 'app-crews',
  standalone: true,
  imports: [
    ButtonModule,
    DialogModule,
    DropdownModule,
    FormsModule,
    InputTextModule,
    FloatLabelModule,
    NgIf,
    MultiSelectModule,
    NgForOf,
    JournalCrewComponent,
    CrewCreatorDialogComponent
  ],
  templateUrl: './crews.component.html',
  styleUrl: './crews.component.scss'
})
export class CrewsComponent implements OnInit {
  showCrewEditor = false;
  showCrewMemberEditor = false;

  crew?: Crew;
  selectedCrewMember?: CrewMember;

  crewMembers: CrewMember[] = [];
  crews: Crew[] = [];

  constructor(private crewService: CrewService,
              private alertService: AlertModalService,
              private loader: LoaderService,
              private securityQuery: SecurityQueryService) {
    //
  }

  async ngOnInit() {
    try {
      this.loader.visibility = true;
      this.crewMembers = await this.crewService.getCrewMembers();
      this.crews = await this.crewService.getCrews();
    } catch (e) {
      //
    } finally {
      this.loader.visibility = false;
    }
  }

  async selectCrew(crew: Crew) {
    try {
      this.loader.visibility = true;
      this.crew = await this.crewService.getCrew(crew.id);
      this.showCrewEditor = true;
    } finally {
      this.loader.visibility = false;
    }
  }

  createBlankCrew() {
    this.crew = new Crew();
    this.crew.crewMembers = [];
    this.showCrewEditor = true;
  }

  crewDeleted(crew: Crew) {
    this.crews = this.crews.filter(c => c.id !== crew.id);
  }

  crewUpdated(crew: Crew) {
    this.crews = this.crews.map(c => c.id === crew.id ? crew : c);
  }

}
