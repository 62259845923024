<div class="entities">
  <div *ngFor="let entity of entities" id="entity#{{ entity.id }}" class="entity">
    <div class="entity-header offset">
      <div class="date">
        <i class="pi pi-calendar"></i>
        <span [innerText]="getDate(entity.date)"></span>
      </div>
    </div>
    <div class="text offset" [innerHTML]="entity.text"></div>
    <div class="offset">
      <app-journal-preview-entity-assets
        [entity]="entity"
        (imageClickEvent)="imageClick($event.index, $event.entity)"
        (imageKeyDownEvent)="imageKeyDown($event)"
        (loadMoreEvent)="loadMore($event)"/>
    </div>
  </div>
</div>
