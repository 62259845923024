<div class="track-wrapper">
  <div class="track-grid" [ngClass]="selectedRecord === undefined ? 'fullscreen' : ''">
    <div class="meta">
      <app-journal-stats [stats]="stats" [isVisible]="true"/>
    </div>
    <div class="track-stats">
      <app-track-record-data [trackRecord]="selectedRecord"/>
    </div>
    <div class="track-map">
      <div id="map"></div>
    </div>
    <div class="track-details" *ngIf="selectedRecord !== undefined">
      <app-track-record-details
        [title]="track!.name"
        (titleChanged)="track!.name = $event; saveTrack()"
        (recordSelected)="selectRecordData($event)"
        [availableRecords]="track?.records ?? []"
        [trackRecord]="selectedRecord"/>
    </div>
  </div>
</div>

<button [ngClass]="selectedRecord === undefined ? 'hide' : ''" (click)="resetMap()" id="reset-map">
  <img src="assets/undo.svg">
  <span>Zurück</span>
</button>

<!-- <div class="container">
  <div id="map"></div>
  <div *ngIf="track !== undefined" class="info">

    <input type="text" [(ngModel)]="track!.name" (keydown)="saveTrack($event)" placeholder="Track-Bezeichnung" />

    <div class="journal">

      <div *ngIf="track?.journal == null" class="alert">
        <img src="assets/warning.svg">
        <span>Dieser Track ist noch keinem Törn zugeordnet!</span>
      </div>

      <div *ngIf="track?.journal == null" class="selection">
        <div class="form-group">
          <select [(ngModel)]="journalId" (ngModelChange)="setJournal()">
            <option selected value="-1">Törn auswählen</option>
            <option *ngFor="let journal of journals" [value]="journal.id">{{ journal.revier ?? '' }} {{ journal.year ?? '' }}</option>
            <option value="0">Neuer Törn</option>
          </select>
        </div>
        <button (click)="saveJournal()" type="button">Verknüpfen</button>
      </div>

      <div class="date">
        <div>{{ getDate(track?.createdAt) ?? '' }}</div>
        <span> - {{ track?.journal?.revier ?? '-/-' }}</span>
      </div>

      <div *ngIf="journalEntity !== undefined && !journal?.readonly" style="color: BLACK" class="NgxEditor__Wrapper">
        <ngx-editor-menu [editor]="editor"></ngx-editor-menu>
        <ngx-editor (focusOut)="updateJournal()" [editor]="editor" [(ngModel)]="journalEntity!.text" [disabled]="false" [placeholder]="'Schreibe etwas über den Tag...'"></ngx-editor>
      </div>

      <div *ngIf="journalEntity !== undefined && journal?.readonly" [innerHTML]="journalEntity?.text ?? ''"></div>

    </div>
  </div>
  <div *ngIf="track?.records?.length ?? 0 > 0" class="records">
    <div *ngFor="let record of track?.records ?? []" (click)="selectRecord(record)" class="record">
      <div class="record-time">{{ getTime(record) }}</div>
    </div>
  </div>
</div> -->

<app-journal-creation *ngIf="creationModal" (created)="loadJournals($event)"
                      (closed)="creationModal = false; journalId = -1"/>
