import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpEvent, HttpEventType} from "@angular/common/http";
import {Crew} from "../models/Crew";
import {catchError, EMPTY, Subject} from "rxjs";
import {CrewMember} from "../models/CrewMember";
import {Journal} from "../models/Journal";
import {BaseService, HttpMethod} from "./base.service";
import {AssetType} from "../classes/AssetType";
import {JournalStats} from "../classes/JournalStats";
import {JournalEntityAsset} from "../models/JournalEntityAsset";
import {PortfolioSection} from "../models/PortfolioSection";
import {FaqItem} from "../models/FaqItem";

@Injectable()
export class CmsService {

  constructor(private base: BaseService) {
    //
  }

  async getSections() {
    return await this.base.executeRequest<PortfolioSection[]>('/portfolio/sections', HttpMethod.GET);
  }

  async getSection(sectionId: string) {
    return await this.base.executeRequest<PortfolioSection>('/portfolio/section/' + sectionId, HttpMethod.GET);
  }

  async updateSection(section: PortfolioSection) {
    return await this.base.executeRequest<PortfolioSection>('/portfolio/section', HttpMethod.PATCH, section);
  }

  async getFaqQuestions() {
    return await this.base.executeRequest<FaqItem[]>('/faq/questions', HttpMethod.GET);
  }

  async createFaqQuestion(question: FaqItem) {
    return await this.base.executeRequest<FaqItem>('/faq/question', HttpMethod.POST, question);
  }

  async updateFaqQuestion(question: FaqItem) {
    return await this.base.executeRequest<FaqItem>('/faq/question', HttpMethod.PATCH, question);
  }

  async deleteFaqQuestion(questionId: number) {
    return await this.base.executeRequest('/faq/question/' + questionId, HttpMethod.DELETE);
  }

}
