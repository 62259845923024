import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";
import {Journal} from "../../../models/Journal";
import {RegistryService} from "../../../services/registry.service";
import {JournalMapOverviewComponent} from "../journal-map-overview/journal-map-overview.component";
import {LogPrefix} from "../../app.config";

@Component({
  selector: 'app-journal-entity-metadata',
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    JournalMapOverviewComponent
  ],
  templateUrl: './journal-entity-metadata.component.html',
  styleUrl: './journal-entity-metadata.component.scss'
})
export class JournalEntityMetadataComponent implements AfterViewInit {

  @Input() journal?: Journal;

  @Output() save = new EventEmitter<void>();

  @Output() editModeChanged = new EventEmitter<boolean>();
  @Input() editMode: boolean = false;

  @Output() shareButtonClicked = new EventEmitter<void>();
  @Output() appButtonClicked = new EventEmitter<void>();

  fallbackFlagIcon = 'assets/world.svg';
  countryFlag?: string = this.fallbackFlagIcon;

  constructor(private services: RegistryService) {
    //
  }

  async ngAfterViewInit() {
    while (this.journal === undefined) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
    this.countryFlag = await this.setCountryFlag(this.journal.country);
  }

  tryEdit() {
    if (this.journal?.readonly) {
      return;
    }
    this.editModeChanged.emit(true);
  }

  cancelEdit() {
    this.editModeChanged.emit(false);
    this.save.emit();
    this.setCountryFlag(this.journal!.country).then(flag => this.countryFlag = flag);
  }

  keyDownEvent(event: any) {
    if (event.key === 'Enter') {
      this.cancelEdit();
    }
  }

  async setCountryFlag(country?: string) {
    try {
      if (country !== undefined && country !== '' && country.length > 3) {
        const result = await this.services.external.getCountries(country);
        for (const country of result) {
          return country.flags.svg;
        }
      }
    } catch (e) {
      //
    }
    return this.fallbackFlagIcon;
  }

}
