<div class="wrapper">
  <div class="title">
    Fehlerprotokolle
  </div>
  <p-progressBar *ngIf="errors == undefined"
                 mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
  <div *ngIf="errors !== undefined" class="table">
    <div class="table-header" style="color: WHITE; align-items: center">
      <div class="sm">ID</div>
      <div>Fehlernachricht</div>
      <div>Zeitpunkt</div>
      <div class="sm">
        <i (click)="loadErrors()" class="pi pi-refresh" style="font-size: 1.2rem"></i>
      </div>
    </div>
    <div *ngFor="let item of errors; let i = index"
         (click)="item.modalVisible = true"
         class="table-content"
         [ngClass]="isEven(i) ? 'dark-row' : ''">
      <div class="sm">{{ item.id }}</div>
      <div>{{ item.message }}</div>
      <div>{{ getTime(item) }}</div>
      <div class="sm"></div>
    </div>
  </div>
</div>

<p-dialog *ngFor="let item of errors"
          header="{{ item.message }} - {{ getTime(item) }}"
          [modal]="true"
          [(visible)]="item.modalVisible"
          [style]="{ width: '50rem' }"
          [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
          [maximizable]="true">
  <p>
    {{ item.stack }}
  </p>
</p-dialog>
