<div *ngIf="service.visible" class="wrapper">
  <div class="container">
    <div class="title">
      Crew-Editor
    </div>
    <div class="profile-img">
      <img src="assets/profile-user.svg">
    </div>
    <div>
      <input [(ngModel)]="service.obj.name" class="input" type="text" list="names" placeholder="Name">
      <datalist id="names">
        <option *ngFor="let item of crewMembers" [value]="item.name">{{ item.name }}</option>
      </datalist>
    </div>
    <div>
      <input [(ngModel)]="service.obj.email" class="input" type="email" list="emails" placeholder="E-Mail (optional)">
      <datalist *ngIf="possibleMails.length > 0 && possibleMails[0].email != null" id="emails">
        <option *ngFor="let item of possibleMails" [value]="item.email">{{ item.email }}</option>
      </datalist>
    </div>
    <div>
      <select [(ngModel)]="service.role" class="input" type="text" placeholder="Rolle">
        <option [value]="CrewMemberRole.Skipper">Skipper</option>
        <option [value]="CrewMemberRole.CoSkipper">Co-Skipper</option>
        <option [value]="CrewMemberRole.Navigator">Navigator</option>
        <option [value]="CrewMemberRole.Smutje">Smutje</option>
        <option [value]="CrewMemberRole.Crew" selected>Crew</option>
      </select>
    </div>
    <div style="display: flex; gap: 0.5rem">
      <button (click)="service.apply()" class="button" type="button">Übernehmen</button>
      <button (click)="service.cancel()" class="button danger" type="button">Abbrechen</button>
      <button (click)="service.delete()" class="button danger" type="button">Löschen</button>
    </div>
  </div>
</div>
