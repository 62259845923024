<div *ngIf="service.assets.length > 0" class="picture-preview-wrapper">
  <div class="picture-preview-container" [ngClass]="service.gpsDataAvailable() ? '' : 'no-gps-data'">
    <div class="header">
      <div (click)="close.emit()" class="button">
        <img src="assets/close.svg">
        <div>Schließen</div>
      </div>
      <div *ngIf="!service.readonly" (click)="service.deleteSelectedAsset()" class="button danger">
        <img src="assets/trash-bin.svg">
        <div>Löschen</div>
      </div>
    </div>
    <div class="preview">
      <img *ngIf="service.selectedAsset != undefined && service.isImage(service.selectedAsset)"
           [src]="service.selectedAsset.imageUrl" (click)="fullscreen = true">
    </div>
    <div class="map" [ngClass]="service.gpsDataAvailable() ? '' : 'no-data'">
      <app-map/>
    </div>
    <div class="slider">
      <div class="pictures">
        <div *ngFor="let asset of getPictures()" class="picture"
             [ngClass]="asset.id === service.selectedAsset?.id ? 'active' : ''">
          <img [src]="asset.imageUrl" (click)="select(asset)">
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="fullscreen" class="fullscreen">
  <div *ngIf="service.isPreviousAvailable()" (click)="service.selectPrevious()" class="left-arrow">
    <img src="assets/left-gray-arrow.svg">
  </div>
  <div *ngIf="service.isNextAvailable()" (click)="service.selectNext()" class="right-arrow">
    <img src="assets/left-gray-arrow.svg" style="transform: rotate(180deg)">
  </div>
  <img *ngIf="service.selectedAsset != undefined && service.isImage(service.selectedAsset)"
       [src]="service.selectedAsset.imageUrl" (click)="fullscreen = false" class="fullscreen-image">
</div>
