<div class="container">
  <div class="title">
    Account-Einstellungen
  </div>
  <div class="nav" [ngClass]="selectionActive ? 'selection-active' : ''">
    <div class="nav-title">
      Navigation
    </div>
    <div (click)="selectedTab = SelectedTab.Data; selectionActive = true" class="nav-item">
      <i class="pi pi-user"></i>
      <label>Persönliche Daten</label>
    </div>
    <div (click)="selectedTab = SelectedTab.Security; selectionActive = true" class="nav-item">
      <i class="pi pi-shield"></i>
      <label>Sicherheit</label>
    </div>
    <!--<div (click)="selectedTab = SelectedTab.Settings; selectionActive = true" class="nav-item">
      <i class="pi pi-sliders-h"></i>
      <label>Einstellungen</label>
    </div>-->
    <div *ngIf="isAdmin" (click)="selectedTab = SelectedTab.System; selectionActive = true" class="nav-item">
      <i class="pi pi-wrench"></i>
      <label>System-Parameter</label>
    </div>
    <div *ngIf="isAdmin" (click)="selectedTab = SelectedTab.UserManagement; selectionActive = true" class="nav-item">
      <i class="pi pi-users"></i>
      <label>Benutzerverwaltung</label>
    </div>
    <div *ngIf="isAdmin" (click)="selectedTab = SelectedTab.MembershipManagement; selectionActive = true"
         class="nav-item">
      <i class="pi pi-wallet"></i>
      <label>Mitgliedschaften</label>
    </div>
    <div *ngIf="isAdmin" (click)="selectedTab = SelectedTab.ErrorManagement; selectionActive = true" class="nav-item">
      <i class="pi pi-server"></i>
      <label>Fehlerberichte</label>
    </div>
    <div *ngIf="isAdmin" (click)="selectedTab = SelectedTab.TrackDataKeyManagement; selectionActive = true"
         class="nav-item">
      <i class="pi pi-key"></i>
      <label>SignalK - Keys</label>
    </div>
    <div *ngIf="isAdmin" (click)="selectedTab = SelectedTab.Jobs; selectionActive = true" class="nav-item">
      <i class="pi pi-wave-pulse"></i>
      <label>System - Jobs</label>
    </div>
    <div *ngIf="canEditCMS" (click)="selectedTab = SelectedTab.CMS; selectionActive = true" class="nav-item">
      <i class="pi pi-pencil"></i>
      <label>CMS-Management</label>
    </div>
    <div (click)="logout()" class="nav-item logout">
      <i class="pi pi-sign-out"></i>
      <label>Logout</label>
    </div>
  </div>
  <div class="content" [ngClass]="selectionActive ? '' : 'selection-active'">
    <app-settings-data-tab *ngIf="selectedTab == SelectedTab.Data"/>
    <app-settings-security-tab *ngIf="selectedTab == SelectedTab.Security"/>
    <app-settings-general-tab *ngIf="selectedTab == SelectedTab.Settings"/>
    <app-settings-system-tab *ngIf="selectedTab == SelectedTab.System && isAdmin"/>
    <app-user-management *ngIf="selectedTab == SelectedTab.UserManagement && isAdmin"/>
    <app-membership-management *ngIf="selectedTab == SelectedTab.MembershipManagement && isAdmin"/>
    <app-error-management *ngIf="selectedTab == SelectedTab.ErrorManagement && isAdmin"/>
    <app-trackDataKey-management *ngIf="selectedTab == SelectedTab.TrackDataKeyManagement && isAdmin"/>
    <app-jobs-management *ngIf="selectedTab == SelectedTab.Jobs && isAdmin"/>
    <app-cms-management *ngIf="canEditCMS && selectedTab == SelectedTab.CMS"/>
    <div (click)="selectionActive = false" class="close">
      Schließen
    </div>
  </div>
</div>
