import {Injectable} from "@angular/core";
import {BaseService, HttpMethod} from "./base.service";
import {RegistryService} from "./registry.service";
import {Journal} from "../models/Journal";
import {StringHelper} from "../helpers/StringHelper";
import {RestCountry} from "../classes/RestCountry";
import {JournalEntity} from "../models/JournalEntity";
import {DateHelper} from "../helpers/DateHelper";
import {JournalEntityAsset} from "../models/JournalEntityAsset";

@Injectable()
export class TestService {
  constructor(private base: BaseService,
              private services: RegistryService) {
    //
  }

  async createDemoJournals(file: File) {
    for (let i = 0; i < 10; i++) {
      let journal = new Journal();
      journal.title = `Journal ${StringHelper.generateRandomString(4)}`;
      journal.revier = `Revier ${StringHelper.generateRandomString(4)}`;
      journal.boatType = `BoatType ${StringHelper.generateRandomString(4)}`;
      journal.boatName = `BoatName ${StringHelper.generateRandomString(4)}`;
      journal.miles = Math.floor(Math.random() * 1000);
      journal.country = await this.getRandomCountry();
      journal.year = Math.floor(Math.random() * 100) + 1900;
      const journalId = await this.services.journal.postJournal(journal);
      console.log(`Created journal with ID ${journalId}`);

      journal.journals = [];
      journal.id = journalId;

      for (let j = 0; j < 1; j++) {
        const entity = new JournalEntity();
        entity.date = DateHelper.generateRandomDate();
        entity.text = StringHelper.generateRandomString(512);
        journal.journals.push(entity);
      }

      journal = await this.services.journal.putJournal(journal);

      for (const entity of journal.journals) {
        console.log(`Created journal entity with ID ${entity.id}`);
        for (let k = 0; k < 1; k++) {
          await this.services.journal.postAsset(entity.id, file);
          console.log(`Created asset for journal entity with ID ${entity.id}`);
        }
      }

    }
  }

  async getRandomCountry() {
    const result = await this.base.executeRequest<RestCountry[]>(`https://restcountries.com/v3.1/all?fields=name,flags`, HttpMethod.GET);
    const countries: string[] = result.map(c => c.name.common);
    const randomIndex = Math.floor(Math.random() * countries.length);
    return countries[randomIndex];
  }

}
