import {JournalEntityAsset} from "../models/JournalEntityAsset";
import {environment} from "../environments/environment";

export class AssetHelper {

  static isImage(asset: JournalEntityAsset) {
    return asset.filename.toLowerCase().endsWith('.jpg')
      || asset.filename.toLowerCase().endsWith('.png')
      || asset.filename.toLowerCase().endsWith('.jpeg');
  }

  static isVideo(asset: JournalEntityAsset) {
    return asset.filename.toLowerCase().endsWith('.mp4')
      || asset.filename.toLowerCase().endsWith('.mpeg')
      || asset.filename.toLowerCase().endsWith('.mov');
  }

  static getAssetUrl(asset: JournalEntityAsset, quality: "sm" | "md" | "lg" = "sm") {
    return `${environment.backend.protocol}://${environment.backend.host}:${environment.backend.port}/api/v1/journal/link/asset/${asset.secret}?quality=${quality}`;
  }

}
