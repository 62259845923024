import {Component, Input, OnInit} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {EditModalComponent} from "../journal/edit-modal/edit-modal.component";
import {SecurityQueryService} from "./securityQuery.service";

@Component({
  selector: 'app-security-query',
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    EditModalComponent,
    NgForOf
  ],
  templateUrl: './securityQuery.component.html',
  styleUrls: ['./securityQuery.component.scss']
})
export class SecurityQueryComponent {

  constructor(public service: SecurityQueryService) {
  }

  // tslint:disable-next-line:typedef
  get result() {
    return SecurityQueryResult;
  }

}

export enum SecurityQueryResult {
  Yes,
  No,
  Cancel
}
