import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgIf} from "@angular/common";
import {QRCodeModule} from "angularx-qrcode";

@Component({
  selector: 'app-app-connect',
  standalone: true,
  imports: [
    NgIf,
    QRCodeModule
  ],
  templateUrl: './app-connect.component.html',
  styleUrl: './app-connect.component.scss'
})
export class AppConnectComponent {

  @Input() showAppSecret: boolean = false;
  @Input() qrCodeData?: string;
  @Input() readOnly: boolean = true;
  @Output() showAppOverlay = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

}
